import { throwError as observableThrowError, Subject, Observable, Subscription, BehaviorSubject, of, timer } from 'rxjs';
import { APP_ROUTES, SUCCESS_RESPONSE, MODEL, cmsPrefixUrl, cmsSuffixUrl, QUALTRICS_CONST, INVESTMENT_KNOWLEDGE_LEVEL, ACCOUNT_TYPE, COUNTRY_CODE, CDB, JOURNEY_CLIENT_ID, BILJ, AUTHORIZE_URL, productTypeAD, productTypeSD, OLB_CDB_CLIENT_ID, LOGIN_SUCCESS, OLB_AUTHORIZE_URL } from './../../config/app-config.constants';
import { Injectable, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { Utils } from '../../shared/utils/utils.class';
import { UserIdleService } from 'angular-user-idle';
import { FormService } from '../../shared/service/form.service';
import { AnalyticsService } from './analytics.service';
import { retry, switchMap, map } from 'rxjs/operators';
import { QulatricsObj } from '../components/qualtrics/qualtrics.component';
import { LoaderService } from "../../shared/service/loader.service";
import {get} from "lodash";

@Injectable()
export class ApplicationService {
    setCashMarginAccount: Subject<any> = new Subject<any>();
    currentLang: string;
    @Output() beneficiaryPageNavigation: EventEmitter<any> = new EventEmitter();
    @Output() withdrawalPageNavigation: EventEmitter<any> = new EventEmitter();

    @Output() leftNavigation: EventEmitter<any> = new EventEmitter();
    @Output() resumeLink: EventEmitter<any> = new EventEmitter();
    @Output() signoutLink: EventEmitter<any> = new EventEmitter();
    @Output() openResumeApplication: EventEmitter<any> = new EventEmitter();
    @Output() finishLater: EventEmitter<any> = new EventEmitter();
    @Output() partialSave: EventEmitter<any> = new EventEmitter();
    public docuUploadStatus: any;
    // accountType: Subject<any> = new Subject<any>();
    selectedAccount: any;
    appReferences: any;
    appResponse: any;
    appRetrieve: any;
    config: any;
    isIdleTimeOut: boolean;
    isQuebec: boolean;
    applicationNumber: string;
    applicationStatus: any;
    applicationObject: any;
    isAutoSaveEnabled: boolean;
    applicationPageName: any;
    public investmentKnowledgeLevel = INVESTMENT_KNOWLEDGE_LEVEL;
    public netWorthAmount$ = new Subject<any>();
    public resumeApplicationModel$ = new Subject<boolean>();
    public surveyModel$ = new Subject<boolean>();
    public investorModel$ = new Subject<boolean>();
    public finishLaterModel$ = new Subject<any>();
    public signOutModel$ = new Subject<any>();
    private accountFlow$ = new Subject<any>();
    private hidePersonalInformationForOtherAcc = new BehaviorSubject<any>("");
    public showHowADworks$ = new BehaviorSubject<any>("");
    public showContactUsModel$ = new BehaviorSubject<any>("");
    public showOtherAccountsModel$ = new BehaviorSubject<any>("");
    public applyPromoCode$ = new BehaviorSubject<any>("");
    public applicationNumber$ = new BehaviorSubject<any>("");
    public hidePageUndloadPopUp$ = new BehaviorSubject<any>(true);

    public setSubproduct$ = new BehaviorSubject<any>("");
    public selectAccount$ = new BehaviorSubject<any>("");
    public isJourneysUser$ = new BehaviorSubject<boolean>(false);
    public stopPing$ = new Subject<boolean>();
    public showHelp$ = new Subject<boolean>();
    public cmsParametersExt$ = new BehaviorSubject<boolean>(false);
    public triggerRestartModal$ = new BehaviorSubject<boolean>(false);
    public populateSetExpectationForm$ = new BehaviorSubject<boolean>(false);
    public applicationResumed$ = new BehaviorSubject<boolean>(false);
    public marginCancelDowngradeModal$ = new Subject<any>();
    public nonRegUnselected$ = new Subject<boolean>();
    public resumeAppID: string;

    public delay: any;
    public redirectURL;

    // accountTypeTitle: string;
    public isCsaCfrAttorneyFlag: boolean = false;
    public isCsaCfrCAAFormFlag: boolean = false;
    public ceightSixFlag: boolean = false;
    public isDataRationalizationEnabled: boolean = false;
    public isAMLFunctionalityEnabled: boolean = false;
    public isMarginAccountPenetrationEnabled: boolean = false;
    cmsParamExtSubscription: Subscription;
    private pingSubscription: Subscription;
    public ref: any;

    private timeout = new BehaviorSubject(false);
    cast = this.timeout.asObservable();
    cmsMessageObj$;
    // createOlbWorkflowID = '';
    /***   For signout-save */
    public signOutClicked = new EventEmitter<any>();
    public onSignOutCheckValidity = new EventEmitter<any>();
    public validForm: boolean;
    public onSignOutPerformSave = new EventEmitter<any>();
    public saveOperationPerformed = new EventEmitter<any>();
    public onExitApplicationPerformSave = new EventEmitter<any>();
    public exitApplicationModal = new EventEmitter<boolean>(false);

    private API_CACHE = new Map<string, any>();
    public hasTradingKnowledge: any;
    queryParams;
    //private isFirstSaveInnvocation: boolean = true;  //will commnet once tested on dit env

    constructor(
        public translate: TranslateService,
        private formService: FormService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private configService: ConfigResolverService<any>,
        private analyticsService: AnalyticsService,
        public resolver: ComponentFactoryResolver,
         private loaderService: LoaderService) {

        // if (!this.configService.config) {
        //   if (environment.name === 'prod-assisted') {
        //     this.configService.setConfig('./assets/config/prod.BRANCH-ASSITED.env.json');

        //} else if (environment.name === 'prod-selfserve') {
        //  this.configService.setConfig('./assets/config/prod.SELF_SERVE.env.json');

        //}   else if (environment.name === 'sit-ba') {
        //  this.configService.setConfig('./assets/config/sit.BA.env.json');

        //}   else if (environment.name === 'sit-ss') {
        //  this.configService.setConfig('./assets/config/sit.SS.env.json');

        //}   else if (environment.name === 'sit') {
        //  this.configService.setConfig('./assets/config/sit.env.json');

        //} else {
        // this.configService.setConfig('./assets/config/dev.env.json');
        //}
        //}


        if (this.delay) {
            clearInterval(this.delay);
        }
    }

    getEnforceFinishLaterFeatureFlag() {
        if (Utils.getCmsMessageObject() && Utils.getCmsMessageObject().selfServe) {
            return Utils.getCmsMessageObject().selfServe.isEnforceFinishLaterEnabled;
        } else {
            return false;
        }
    }

    getActualOlbLoginFeatureFlag() {
        if (
            Utils.getCmsMessageObject() &&
            Utils.getCmsMessageObject().selfServe
        ) {
            return Utils.getCmsMessageObject().selfServe.actualOlbLogin;
        } else {
            return false;
        }
    }
    getLmeFeatureFlag() {
        if (
            Utils.getCmsMessageObject() &&
            Utils.getCmsMessageObject().selfServe
        ) {
            return Utils.getCmsMessageObject().selfServe.enableOrDisableLme;
        } else {
            return false;
        }
    }
    getisCsaCfrCAAFormFeatureFlag() {
        this.cmsParamExtSubscription = this.cmsParametersExt$.subscribe(
            (params) => {
                if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                    this.isCsaCfrCAAFormFlag =
                        Utils.getCmsMessageObject()["isCsaCfrCAAFormEnabled"];
                }
            }
        );

        return this.isCsaCfrCAAFormFlag;
    }



    getMultiAccountOpenFeatureFlag() {
        return Utils.getCmsMessageObject()
            ? Utils.getCmsMessageObject()["isMultiAccountOpen"]
            : false;
    }

    getCookieModalFeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()?.featureFlags?.isCookieModalEnabled : false;
    }

    getSTPOptionsFeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['isSTPOptionsEnabled'] : false;
    }

    getDataRationalization4FeatureFlag() {
        return Utils.getCmsMessageObject()
            ? Utils.getCmsMessageObject()["isDataRationalization4Enabled"]
            : false;
    }
    getReferAFriendFeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['disableReferAfriend'] : false;
    }

    getCanadaPostKey() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['canadaPostKey'] : null;
    }
    getCommissionAndFeesFeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['enableCommissionAndFees'] : false;
    }

    getBill64FeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()?.featureFlags?.isBill64Enabled : false;
    }

    // getC86FeatureFlag() {
    // this.getCMSMessage().subscribe(response => {
    //     if (response && Utils.showCMSMessage) {
    //         this.ceightSixFlag = Utils.getCmsMessageObject()['isCeightSixEnabled']
    //     }
    // });
    getCsaCfrAttorneyFeatureFlag() {
        this.cmsParamExtSubscription = this.cmsParametersExt$.subscribe(
            (params) => {
                if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                    this.isCsaCfrAttorneyFlag =
                        Utils.getCmsMessageObject()["isCsaCfrAttorneyEnabled"];
                }
            }
        );

        return this.isCsaCfrAttorneyFlag;
    }

    emitSelectCashOrMarginAccount(setCashMarginAccount) {
        this.setCashMarginAccount.next(setCashMarginAccount);
    }

    getC86FeatureFlag() {
        this.cmsParamExtSubscription = this.cmsParametersExt$.subscribe(
            (params) => {
                if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                    this.ceightSixFlag =
                        Utils.getCmsMessageObject()["isCeightSixEnabled"];
                }
            }
        );

        return this.ceightSixFlag;
    }

    getDataRationalizationFeatureFlag() {
        return Utils.getCmsMessageObject()
            ? Utils.getCmsMessageObject()["isDataRationalizationEnabled"]
            : false;
    }

    getCIROEnabledFeatureFlag() {
        return !!Utils.getCmsMessageObject()?.featureFlags?.['isCIROEnabled'];
    }

    getADContentFeatureFlag() {
        return !!Utils.getCmsMessageObject()?.featureFlags?.['enableNewContentForAdModal'];
    }

    getAMLFunctionalityFlag() {
        this.cmsParamExtSubscription = this.cmsParametersExt$.subscribe(
            (params) => {
                if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                    this.isAMLFunctionalityEnabled =
                        Utils.getCmsMessageObject()[
                        "isAMLFunctionalityEnabled"
                        ];
                }
            }
        );

        return this.isAMLFunctionalityEnabled;
    }
    getCaaTaxResidencyCertificateFlag() {
        let caaTaxCertificate: boolean = false;
        this.cmsParamExtSubscription = this.cmsParametersExt$.subscribe(
            (params) => {
                if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                    caaTaxCertificate =
                        Utils.getCmsMessageObject()?.featureFlags
                            ?.isCaaTaxResidencyCertificateEnabled;
                }
            }
        );

        return caaTaxCertificate;
    }

    getMarginAccountPenetrationFeatureFlag() {
        return Utils.getCmsMessageObject()
            ? Utils.getCmsMessageObject()["isMarginAccountPenetrationEnabled"]
            : false;
    }

    getNewContentR5EnabledFeatureFlag() {
        return Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['isNewContentR5Enabled'] : false;
    }

    getCRAtaxresidencyEnabledFeatureFlag() {
        return !!Utils.getCmsMessageObject()?.featureFlags?.['isCRAtaxresidencyEnabled'];
    }

    setBeneficiaryNavigationDisabled(value) {
        this.beneficiaryPageNavigation.emit(value); // Emit Value to set  the Navigation (Enable or Disable )
    }

    getBeneficiaryNavigationDisabled() {
        // Getter for navigationDisabled status  when Emitted
        return this.beneficiaryPageNavigation;
    }

    setWithdrawalNavigationDisabled(value) {
        this.withdrawalPageNavigation.emit(value);
    }

    getWithdrawalNavigationDisabled() {
        return this.withdrawalPageNavigation;
    }

    setNavigationDisabled(value) {
        this.leftNavigation.emit(value); // Emit Value to set  the Navigation (Enable or Disable )
    }

    getNavigationDisabled() {
        // Getter for navigationDisabled status  when Emitted
        return this.leftNavigation;
    }

    sethidePersonalInfor(value) {
        this.hidePersonalInformationForOtherAcc.next(value);
    }

    gethidePersonalInfor() {
        return this.hidePersonalInformationForOtherAcc.asObservable();
    }

    setResumeLink(value) {
        this.resumeLink.emit(value); // Emit Value to show or hide resume application link
    }

    getResumeLink() {
        // Getter for resumeLink status  when Emitted
        return this.resumeLink;
    }

    setSignOutLink(value) {
        this.signoutLink.emit(value); // Emit Value to show or hide signoutLink
    }

    getSignOutLink() {
        // Getter for signoutLink status  when Emitted
        return this.signoutLink;
    }

    generateURL() {
        return window.location.href
            .split("#/")[0]
            .concat("#/select-account?lang=")
            .concat(this.getCurrentLanguage())
            .concat("&subProduct=")
            .concat(Utils.getSubProduct());
    }
    generateURLNoSubproduct() {
        return window.location.href
            .split("#/")[0]
            .concat("#/select-account?lang=")
            .concat(this.getCurrentLanguage());
    }
    generateURLTYSK() {
        return window.location.href
            .split("#/")[0]
            .concat("#/set-expectation?lang=")
            .concat(this.getCurrentLanguage())
            .concat("&subProduct=")
            .concat(Utils.getSubProduct());
    }
    generateURLTYSKNoSubproduct() {
        return window.location.href
            .split("#/")[0]
            .concat("#/set-expectation?lang=")
            .concat(this.getCurrentLanguage());
    }
    getAutoSaveEnabled() {
        return this.isAutoSaveEnabled;
    }
    setAutoSaveEnabled(value) {
        this.isAutoSaveEnabled = value;
    }

    setHasTradingKnowledge(value) {
        this.hasTradingKnowledge = value;
    }

    getHasTradingKnowledge() {
        return this.hasTradingKnowledge;
    }
    openResumeApplicationModel() {
        this.resumeApplicationModel$.next(true);
    }

    getResumeApplicationModel() {
        return this.resumeApplicationModel$.asObservable();
    }

    setAccountFlow(value) {
        this.accountFlow$.next(value);
    }

    getAccountFlow() {
        return this.accountFlow$.asObservable();
    }

    openInvestorModel() {
        this.investorModel$.next(true);
    }

    getInvestorApplicationModel() {
        return this.investorModel$.asObservable();
    }

    openFinishLater(value) {
        this.finishLaterModel$.next(value);
    }
    getFinishLaterModel() {
        return this.finishLaterModel$.asObservable();
    }

    openModel(value, modelName) {
        switch (modelName) {
            case MODEL.signOut: {
                this.signOutModel$.next(value);
                this.signOutClicked.emit(true);
                break;
            }
        }
    }
    getModel(modelName) {
        switch (modelName) {
            case MODEL.signOut:
                return this.signOutModel$.asObservable();
        }
    }

    setNetWorthAmount(value) {
        this.netWorthAmount$.next(value);
    }

    getNetWorthAmount() {
        return this.netWorthAmount$.asObservable();
    }

    onSignOut() {
        let result: any;

        this.config = this.configService.getConfig();
        const logoutUrl =
            this.config.SESSION_API.CONTEXT_ROOT +
            this.config.SESSION_API.LOGOUT_URI;
        this.http
            .get(logoutUrl, { responseType: "text", observe: "response" })
            .toPromise()
            .then((response) => {
                // console.log("response--", response);
                if (response.status === SUCCESS_RESPONSE) {
                    result = "success";
                    Utils.setIsInSession(false);
                    this.hidePageUndloadPopUp$.next(false);

                    /*Re-route on success of logout api call*/
                    window.location.reload();

                    return result;
                }
            })
            .catch((e) => {
                result = "failed";
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "signout",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return result;
            });
    }

    onSignOutNextStepsPage() {
        let result: any;

        this.config = this.configService.getConfig();
        const logoutUrl =
            this.config.SESSION_API.CONTEXT_ROOT +
            this.config.SESSION_API.LOGOUT_URI;
        this.http
            .get(logoutUrl, { responseType: "text", observe: "response" })
            .toPromise()
            .then((response) => {
                // console.log("response--", response);
                if (response.status === SUCCESS_RESPONSE) {
                    result = "success";
                    Utils.setIsInSession(false);
                    this.hidePageUndloadPopUp$.next(false);
                    sessionStorage.removeItem("NextStep");
                    Utils.setIsOLBUser(false);
                    if (Utils.getSubProduct()) {
                        window.location.href = this.generateURLTYSK();
                    } else {
                        window.location.href = this.generateURLTYSKNoSubproduct();
                    }
                    /*Re-route on success of logout api call*/
                    window.location.reload();
                    return result;
                }
            })
            .catch((e) => {
                result = "failed";
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "signout",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return result;
            });
    }
    stopUserIdlePing(value) {
        this.stopPing$.next(value);
    }

    showHelpOnHeaders(value) {
        this.showHelp$.next(value);
    }

    cmsParametersExtracted(value) {
        this.cmsParametersExt$.next(value);
    }

    setOpenResumeApplication(value) {
        this.openResumeApplication.emit(value); // Emit Value to show or hide resume application link
    }

    getOpenResumeApplication() {
        // Getter for resumeLink status  when Emitted
        return this.openResumeApplication;
    }

    setFinishLater(value) {
        this.finishLater.emit(value); // Emit Value to show or hide resume application link
    }

    getFinishLater() {
        // Getter for resumeLink status  when Emitted
        return this.finishLater;
    }

    setPartialSave(value) {
        this.partialSave.emit(value); // Emit Value to show or hide resume application link
    }

    getPartialSave() {
        // Getter for resumeLink status  when Emitted
        return this.partialSave;
    }

    setExitApplicationModal(value) {
        this.exitApplicationModal.emit(value);
    }

    setCurrentLanguage = () => {
        this.route.queryParams.subscribe((params) => {
            if (params.lang) {
                this.currentLang = params.lang;
            } else {
                this.currentLang = "en";
            }
            this.translateLanguage(this.currentLang);
        });
    };

    setIdleTimeOut(isTimeOut) {
        this.isIdleTimeOut = isTimeOut;
    }

    updateTimeout(value) {
        this.timeout.next(value);
    }

    // After initial calls to webservice, there is no interaction until the end of the application where we
    // submit the application by using Save call. If the user takes more than normal time to complete the application,
    // then there are chances of loosing the ISAM session, so in order to keep that alive, there has to be a ping mechanism
    pingToResetISAMSession() {
        // if (!this.pingSubscription) {
        //     this.pingSubscription = this.userIdle.ping$.subscribe(() => {

        this.config = this.configService.getConfig();
        const healthUrl =
            this.config.SESSION_API.CONTEXT_ROOT +
            this.config.SESSION_API.HEALTH_URI;
        this.http
            .get(healthUrl)
            .toPromise()
            .then((response) => {
                // Utils.log('ping ISAM -' + Date());
                this.loaderService.setOverlay(true); // MWB-16305
                this.loaderService.hide();
            })
            .catch((e) => {
                if (e.status != 502) {
                    this.analyticsService.setapiCallDetails({
                        apiCallMade: "true",
                        apiCallType: "ResetISAMSession",
                        apiCallResponseCode: e.error?.errorInfo
                            ? e.status?.toString() +
                            "-" +
                            e.error?.errorInfo.errorCode.toString()
                            : e.status?.toString(),
                    });
                }
                if (e.status != 500) {
                    this.updateTriggerRestartModal(true);
                }
                // Utils.log('error while pinging ISAM -');
                // Utils.log(e);
            });

        // });
        // }
    }

    updateTriggerRestartModal(value) {
        this.triggerRestartModal$.next(value);
    }

    getCurrentLanguage = (): string => {
        return this.translate.currentLang;
    };
    isQuebecStatus = (): boolean => {
        return this.isQuebec;
    };

    setIsQuebecStatus = (status) => {
        this.isQuebec = status;
    };

    isCurrentLangEnglish = (): boolean => {
        const currLang: string = this.getCurrentLanguage();
        if (currLang === "en") {
            return true;
        }
        return false;
    };

    isCurrentLangFrench = (): boolean => {
        const currLang: string = this.getCurrentLanguage();
        if (currLang === "fr") {
            return true;
        }
        return false;
    };

    isNextStepPage(): boolean {
        let routePath =
            window.location.href.split("#/").length === 2
                ? window.location.href.split("#/")[1].split("?")[0]
                : "";
        return routePath === APP_ROUTES.next_steps;
    }

    translateLanguage = (lang) => {
        this.translate.use(lang);
    };

    getItemFromCode(code: string, sourceArray: any[]) {
        if (code && sourceArray) {
            let value = sourceArray.find((el) => {
                return el.code === code;
            });
            return value || {};
        }
    }

    getLabelFromCode(code: string, sourceArray: any[]) {
        if (code && sourceArray) {
            let value = sourceArray.find((el) => {
                return el.code === code;
            });
            if (value) {
                return value.label;
            }
            return "";
        }
    }

    getQualtricsParameters(qulatricsObj: QulatricsObj) {
        let cmsMessageObject;
        let randomNum1To100;
        let isNextStep = this.isNextStepPage();
        if (
            this.cookieStorage.getItem(QUALTRICS_CONST.QUALTRICS_COOKIE) !==
            "passed"
        ) {
            if (
                this.router.url.match(APP_ROUTES.next_steps) &&
                sessionStorage.getItem("surveyNumber")
            ) {
                randomNum1To100 = sessionStorage.getItem("surveyNumber");
            } else {
                randomNum1To100 = Math.floor(Math.random() * 100 * 1);
                sessionStorage.setItem("surveyNumber", randomNum1To100);
            }
            if (Utils.getCmsMessageObject()) {
                cmsMessageObject = Utils.getCmsMessageObject();
                if (
                    isNextStep &&
                    cmsMessageObject.selfServe.qualtricsParameters
                        ?.enablePostTransSurvey &&
                    cmsMessageObject.selfServe.qualtricsParameters
                        ?.postTransTimeDelayInSeconds > 0
                ) {
                    if (
                        randomNum1To100 <=
                        (cmsMessageObject.selfServe.qualtricsParameters
                            ?.postTransSampleRateInPercentage
                            ? cmsMessageObject.selfServe.qualtricsParameters
                                ?.postTransSampleRateInPercentage
                            : 100)
                    ) {
                        qulatricsObj.surveyType =
                            QUALTRICS_CONST.POST_TRANSACTION_SURVEY;
                        qulatricsObj.timeDelayInSecs =
                            cmsMessageObject.selfServe.qualtricsParameters?.postTransTimeDelayInSeconds;
                    }
                } else if (
                    !isNextStep &&
                    cmsMessageObject.selfServe.qualtricsParameters
                        ?.enableAbandonmentSurvey &&
                    cmsMessageObject.selfServe.qualtricsParameters
                        ?.abandonmentTimeDelayInSeconds > 0
                ) {
                    if (
                        randomNum1To100 <=
                        (cmsMessageObject.selfServe.qualtricsParameters
                            ?.abandonmentSampleRateInPercentage
                            ? cmsMessageObject.selfServe.qualtricsParameters
                                ?.abandonmentSampleRateInPercentage
                            : 100)
                    ) {
                        qulatricsObj.surveyType =
                            QUALTRICS_CONST.ABANDOMENT_SURVEY;
                        qulatricsObj.timeDelayInSecs =
                            cmsMessageObject.selfServe.qualtricsParameters?.abandonmentTimeDelayInSeconds;
                    }
                }
            }
        }
    }

    pageTitles() {
        this.translate.get("pageTitle.documentUpload").subscribe((res) => {
            Utils.setPageTitleDocumentUpload(res);
        });

        this.translate.get("pageTitle.resumeApplication").subscribe((res) => {
            Utils.setpageTitleResumeApplication(res);
        });

        this.translate.get("pageTitle.accountSelection").subscribe((res) => {
            Utils.setPageTitleAccountSelection(res);
        });

        this.translate.get("pageTitle.branchSaveProgress").subscribe((res) => {
            Utils.setPageTitleSaveForLater(res);
        });

        this.translate.get("pageTitle.accountDetails").subscribe((res) => {
            Utils.setPageTitleAccountDetails(res);
        });

        this.translate.get("pageTitle.thingsYouShouldKnow").subscribe((res) => {
            Utils.setPageTitleThingsYouShouldKnow(res);
        });

        this.translate.get("pageTitle.personalInformation").subscribe((res) => {
            Utils.setPageTitlePersonalInformation(res);
        });

        this.translate.get("pageTitle.contactInformation").subscribe((res) => {
            Utils.setPageTitleContactInformation(res);
        });

        this.translate.get("pageTitle.employmentDetails").subscribe((res) => {
            Utils.setPageTitleEmploymentDetails(res);
        });

        this.translate.get("pageTitle.familyInformation").subscribe((res) => {
            Utils.setPageTitleFamilyInformation(res);
        });

        this.translate.get("pageTitle.beneficiary").subscribe((res) => {
            Utils.setPageTitleBeneficiaryInformation(res);
        });

        this.translate.get("pageTitle.financialDetails").subscribe((res) => {
            Utils.setPageTitleFinancialDetails(res);
        });

        this.translate.get("pageTitle.withdrawal").subscribe((res) => {
            Utils.setPageTitleWithdrawalInformation(res);
        });

        if (Utils.isSubProductAD()) {
            this.translate
                .get("pageTitle.investmentKnowledge")
                .subscribe((res) => {
                    Utils.setPageTitleInvestmentKnowlwedge(res);
                });

            this.translate
                .get("pageTitle.investmentObjectives")
                .subscribe((res) => {
                    Utils.setPageTitleInvestmentObjectives(res);
                });

            this.translate.get("pageTitle.investorProfile").subscribe((res) => {
                Utils.setPageTitleInvestorProfile(res);
            });

            this.translate.get("pageTitle.riskAndReturn").subscribe((res) => {
                Utils.setPageTitleRiskAndReturn(res);
            });
        }

        this.translate
            .get("pageTitle.regulatoryDisclosures")
            .subscribe((res) => {
                Utils.setPageTitleRegulatoryDisclosures(res);
            });

        this.translate.get("pageTitle.consents").subscribe((res) => {
            Utils.setPageTitleConsents(res);
        });

        this.translate.get("pageTitle.BMORelationship").subscribe((res) => {
            Utils.setPageTitleBMORelationship(res);
        });

        this.translate.get("pageTitle.accountPassword").subscribe((res) => {
            Utils.setPageTitleAccountPassword(res);
        });

        this.translate.get("pageTitle.finalReview").subscribe((res) => {
            Utils.setPageTitleFinalReview(res);
        });

        this.translate.get("pageTitle.eSignature").subscribe((res) => {
            Utils.setPageTitleESignature(res);
        });

        this.translate.get("pageTitle.completion").subscribe((res) => {
            Utils.setPageTitleCompletion(res);
        });

        this.translate.get("pageTitle.celebrationHalfWay").subscribe((res) => {
            Utils.setPageTitleMidWayCelebration(res);
        });

        this.translate.get("pageTitle.celebrationFinal").subscribe((res) => {
            Utils.setPageTitleFinalCelebration(res);
        });
    }

    // setAccountType = (selectedAccount) => {
    //     // this.accountType = accountType;
    //     this.selectedAccount = selectedAccount;

    //     Utils.log('The selected account ');
    //     Utils.dir(this.selectedAccount);

    //     this.accountType.next(this.selectedAccount.title);

    //     // Utils.log('set accoutn typ' +accountTypeObject);
    //     // Utils.log(accountTypeObject.productNameEN);
    // }
    saveAndcontinue(currentForm, nextComponentUrl: string, lang: string = null) {
        let _lang = Utils.getLanguage();
        let _subProductADC = Utils.getSubProduct();

        if (lang !== null) {
            _lang = lang;
        }

        // navParameter is being added to set the lang into url can be removed once we have actual url
        // skipLocationChange: Navigates without pushing a new state into history.
        let navParameter: NavigationExtras = {};

        if (Utils.isSubProductAD()) {
            if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        subProduct: _subProductADC,
                        mboxEdit: Utils.getMboxEdit(),
                        mboxDisable: Utils.getMboxDisable(),
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        subProduct: _subProductADC,
                    },
                };
            }
        } else {
            if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        mboxEdit: Utils.getMboxEdit(),
                        mboxDisable: Utils.getMboxDisable(),
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                    },
                };
            }
        }

        if (
            Utils.getIsOLBUser() &&
            this.router.url.split("?")[0] === "/" + APP_ROUTES.olb_inprogress
        ) {
            if (
                nextComponentUrl === APP_ROUTES.select_account &&
                sessionStorage.getItem("promoCode")
            ) {
                navParameter.queryParams["promocode"] =
                    sessionStorage.getItem("promoCode");
            }
            navParameter["replaceUrl"] = true;
        }
        this.router.navigate(["/" + nextComponentUrl], navParameter);
    }

    continue(nextComponentUrl: string, lang: string = null) {
        let _lang = Utils.getLanguage();
        let _subProductADC = Utils.getSubProduct();

        if (lang !== null) {
            _lang = lang;
        }

        // navParameter is being added to set the lang into url can be removed once we have actual url
        // skipLocationChange: Navigates without pushing a new state into history.
        let navParameter: NavigationExtras = {};

        if (Utils.isSubProductAD()) {
            if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        subProduct: _subProductADC,
                        mboxEdit: Utils.getMboxEdit(),
                        mboxDisable: Utils.getMboxDisable(),
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        subProduct: _subProductADC,
                    },
                };
            }
        } else {
            if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                        mboxEdit: Utils.getMboxEdit(),
                        mboxDisable: Utils.getMboxDisable(),
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: _lang,
                    },
                };
            }
        }

        this.router.navigate(["/" + nextComponentUrl], navParameter);
    }

    applyPromoCode(lang, promocode) {
        this.config = this.configService.getConfig();
        const extraParamsForLang = "-ca";
        if (lang === undefined || lang === null) {
            lang = Utils.getLanguage() + extraParamsForLang;
        } else {
            lang = lang + extraParamsForLang;
        }
        const promoCodeUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.PROMO_CODE +
            promocode;
        let header = this.config.CHANNELS_API.PROMO_CODE_HEADER;
        header = new HttpHeaders(header);
        return this.http
            .post(promoCodeUrl, { lang: lang }, { headers: header })
            .toPromise()
            .then((response) => {
                // Utils.log('calling promo code -' +  Date());
                return response;
            })
            .catch((e) => {
                // Utils.log('promo code service failed', e);
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "applyPromoCode",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return { isReferenceCallFailed: true };
            });
    }

    getReferences(lang) {
        this.config = this.configService.getConfig();
        const extraParamsForLang = "-ca";

        // defaulting language to english in case if requester could not send language parameter
        if (lang === undefined || lang === null) {
            lang = Utils.getLanguage() + extraParamsForLang;
        } else {
            lang = lang + extraParamsForLang;
        }

        const refUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT_MICROSERVICE +
            this.config.CHANNELS_API.REFERENCE_URI;

        let header = this.config.CHANNELS_API.REFERENCE_HEADER;
        header = new HttpHeaders(header);
        // console.log('DATA', refUrl)

        return this.http
            .get(refUrl, { params: { lang: lang }, headers: header })
            .toPromise()
            .then((response) => {
                this.appReferences = response;
                Utils.setReferences(response);
                return response;
            })
            .catch((e) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "getReferences",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return { isReferenceCallFailed: true };

                /*return this.http.get('assets/data/' + lang + '-references.mock.json')
                    .toPromise()
                    .then(response => {
                        this.appReferences = response;
                        return response;
                    }); */
            });
    }

    fetchReferences(route) {
        this.appReferences = Utils.getReferences();

        /*
        if (this.appReferences === undefined) {

            this.appReferences = route.snapshot.data['reference'];
            console.log('data ref:  ', route.snapshot.data['reference']);

            if (this.appReferences === null || this.appReferences === undefined ) {
                this.appReferences = Utils.getReferences();
                console.log('this.appReferences: ', this.appReferences);
            }
        }
        */
    }

    resetAttributes(obj, attrNotToBeResetArr) {
        for (const attr in obj) {
            if (
                obj.hasOwnProperty(attr) &&
                attrNotToBeResetArr.indexOf(attr) <= -1
            ) {
                obj[attr] = null;
            }
        }
    }

    setPageStatus() {
        this.formService.getPayload().pageStatus.thingsYouShouldKnow =
            Utils.getIsBranchAssisted() !== true
                ? this.formService.forms.setExpectation.isCompleted
                : null;
        this.formService.getPayload().pageStatus.personalInformation =
            this.formService.forms.personalInformation.isCompleted;
        this.formService.getPayload().pageStatus.contactInformation =
            this.formService.forms.contactInformation.isCompleted;
        this.formService.getPayload().pageStatus.employmentInformation =
            this.formService.forms.employmentInformation.isCompleted;
        this.formService.getPayload().pageStatus.familyInformation =
            this.formService.forms.familyInformation.isCompleted;
        this.formService.getPayload().pageStatus.beneficiaryInformation =
            this.formService.forms.beneficiaryInformation.isCompleted;
        this.formService.getPayload().pageStatus.financialInformation =
            this.formService.forms.financialInformation.isCompleted;
        this.formService.getPayload().pageStatus.investmentKnowledge =
            this.formService.forms.investmentKnowledge.isCompleted;
        this.formService.getPayload().pageStatus.investmentObjective =
            this.formService.forms.riskAndReturn.isCompleted;
        this.formService.getPayload().pageStatus.investorProfile =
            this.formService.forms.investorProfile.isCompleted;
        this.formService.getPayload().pageStatus.regulatoryDisclosures =
            this.formService.forms.regulatoryDisclosures.isCompleted;
        this.formService.getPayload().pageStatus.consents =
            this.formService.forms.consents.isCompleted;
        this.formService.getPayload().pageStatus.bmoRelationship =
            this.formService.forms.bmoRelationship.isCompleted;
        this.formService.getPayload().pageStatus.withdrawal =
            this.formService.forms.withdrawal.isCompleted;
        this.formService.getPayload().pageStatus.accountPassword = false;
    }

    getApplicationData() {
        return Utils.getApplicationData();
    }

    getPrimaryApplicant() {
        return Utils.getApplicationData().parties[0];
    }
    public getPartyByRefId(refId: string): any {
        return (this.getApplicationData() || []).parties.find(
            (p: any) => (p || {}).partyRefId === refId
        );
    }

    getCMSMessage(forceRetrival = false) {
        let cmsPrefix = cmsPrefixUrl;
        if (window.location.origin.includes("perf1")) {
            cmsPrefix = "/content/perf/bil/messages_";
        }
        const url =
            window.location.origin +
            cmsPrefix +
            Utils.getLanguage() +
            cmsSuffixUrl;
        const cmsMessage = Utils.getCmsMessageObject();
        if ((cmsMessage || cmsMessage === null) && !forceRetrival) {
            this.cmsMessageObj$ = null;
            return of(cmsMessage);
        } else if (this.cmsMessageObj$ && cmsMessage === undefined && !forceRetrival) {
            return timer(100).pipe(switchMap(() => this.getCMSMessage()));
        } else {
            this.cmsMessageObj$ = this.http.get(url);
            return this.cmsMessageObj$;
        }
    }

    saveWithRetry(payload: any) {
        // Set page status
        this.setPageStatus();
        this.config = this.configService.getConfig();
        const saveUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.SAVE_URI;
        let header = this.config.CHANNELS_API.SAVE_HEADER;
        header = new HttpHeaders(header);
        return this.http
            .post(saveUrl, payload, { headers: header, observe: "response" })
            .pipe(retry(2))
            .toPromise()
            .then((response) => {
                this.appResponse = response.body;
                Utils.setapplicationID(this.appResponse.applicationId);
                Utils.setApplicationData(this.appResponse);
                localStorage.setItem("appId", this.appResponse.applicationId);
                this.applicationNumber$.next(
                    this.appResponse.applicationNumber
                );
                // console.log(" Utils.getapplicationID() app service", Utils.getapplicationID());
                return response;
            })
            .catch((e) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "Save",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return observableThrowError(e);
            });
    }

    getOlbUserDetails(payload) {
        //console.log('$$$$$$$ environment name', environment.name);
        if(get(payload, "userId", "") == "null") {
            delete payload.userId;
        }
        this.config = this.configService.getConfig();
        let fiveStarPrgUrl;
        if (this.config.CHANNELS_API.CDB_OLB_MOCK) {
            fiveStarPrgUrl = this.config.CHANNELS_API.CREATE_OLB;
        } else {
            fiveStarPrgUrl =
                this.config.CHANNELS_API.CONTEXT_ROOT +
                this.config.CHANNELS_API.CREATE_OLB;
        }
        let header = this.config.CHANNELS_API.CREATE_OLB_HEADER;
        // header.x_bmo_ecifid = Utils.getOLBUserEcifId();
        // header.bmo_rsa_user_id = Utils.getOLBUserCardNumber();

        header = new HttpHeaders(header);

        const cacheKey = JSON.stringify({
            url: fiveStarPrgUrl,
            ...payload,
        });

        if (this.API_CACHE.get(cacheKey)) {
            return of(this.API_CACHE.get(cacheKey));
        }
        let fiveStarPrg$: Observable<HttpResponse<Object>>;
        if (this.config.CHANNELS_API.CDB_OLB_MOCK) {
            fiveStarPrg$ = this.http.get(fiveStarPrgUrl, {
                headers: header,
                observe: "response",
            });
        } else {
            fiveStarPrg$ = this.http.post(fiveStarPrgUrl, payload, {
                headers: header,
                observe: "response",
            });
        }
        return fiveStarPrg$.pipe(
            map((response) => {
                this.API_CACHE.set(cacheKey, response);
                return response.body;
            })
        );
    }

    transToFormRequest(obj) {
        if (obj && typeof obj === "object") {
            const str = [];
            for (let p in obj)
                str.push(
                    encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                );
            return str.join("&");
        } else {
            return (obj || "").toString();
        }
    }

    async exchangeIsamSessionFromOtherSysToMywealth(dest, subProduct = "SD") {

        let result = {};
        // This if for Local and Dit, to bypass token and session calls
        if (dest === BILJ && (window.location.href.includes('dit') || window.location.href.includes('localhost'))) {
            result = {
                operation: LOGIN_SUCCESS
            };
            Utils.setSubProduct(subProduct);
        } else {
            const lang = Utils.getLanguage();
            // console.log(subProduct);
            Utils.setSubProduct(subProduct);
            let bodyData = {
                grant_type: "authorization_code",
                code: Utils.getAznCode(),
                code_verifier: localStorage.getItem("codeVerifier"),
                client_id: dest === BILJ ? JOURNEY_CLIENT_ID : OLB_CDB_CLIENT_ID,
                redirect_uri:
                    window.location.origin +
                    "/wealth/onboard/onlineinvesting/?lang=" +
                    lang +
                    "&subProduct=" +
                    subProduct +
                    "&dest=" + dest,
            };
            if (Utils.getIsLMEUser()) {
                bodyData.client_id = "olb_authn_spa";
                delete bodyData.code_verifier;
            }
            let response = await fetch("/oauth/token", {
                method: "POST",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                },
                body: this.transToFormRequest(bodyData),
            })
                .then((res) => res.json())
                .then((tokenResponse: any) => {
                    let token: string = tokenResponse.access_token;
                    let httpBody = new HttpParams().set("access_token", token);
                    const headers = new HttpHeaders({
                        "Content-type": "application/x-www-form-urlencoded",
                    });
                    return this.http.post("/oauth/session", httpBody, {
                        headers: headers,
                    });
                });
            result = await response.toPromise().then((response) => {
                return response;
            });
        }
        if (dest === BILJ) {
            return await this.checkSDOwnership().toPromise().then((response: any) => {
                if (response) {
                    Utils.setIsSDOwner(response?.ownership === productTypeSD);
                }
                return result
            }).catch(e => {
                console.error(e);
                return result
            });
        }
        return result;
     /*    const tokenHeader = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        };
        return this.http.post('/aac/sps/oauth/oauth20/token', {
            grant_type: "authorization_code",
            code: Utils.getAznCode(),
            code_verifier: this.storage.get('codeVerifier'),
            client_id: "bil_authn_spa",
            redirect_uri: window.location.origin + '/wealth/onboard/onlineinvesting'
        }, {
            headers:  tokenHeader
        }).pipe(switchMap((tokenResponse: any) => {
            return this.http.post('/aac/sps/oauth/oauth20/session', {
                access_token: tokenResponse.body.access_token
            });
        })) */
    }
    olbInprogress(): any {
        this.config = this.configService.getConfig();
        let olbInprogressUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.INPROGRESS_OLB;
        if (this.config.CHANNELS_API.INPROGRESS_OLB_MOCK) {
            olbInprogressUrl = this.config.CHANNELS_API.INPROGRESS_OLB;
        } else {
            olbInprogressUrl =
                this.config.CHANNELS_API.CONTEXT_ROOT +
                this.config.CHANNELS_API.INPROGRESS_OLB;
        }
        let header = this.config.CHANNELS_API.INPROGRESS_OLB_HEADER;
        header = new HttpHeaders(header);
        const payload = {
            ecifId: Utils.getOLBUserEcifId() ?? Utils.getJourneyUserEcifId(),
            subProduct: Utils.getSubProduct(),
            cardNo: Utils.getOLBUserCardNumber(),
            userId: Utils.getJourneyUserLoginId()
        };
        let olbInprg$: Observable<HttpResponse<Object>>;
        if (this.config.CHANNELS_API.INPROGRESS_OLB_MOCK) {
            olbInprg$ = this.http.get(olbInprogressUrl, {
                headers: header,
                observe: "response",
            });
        } else {
            olbInprg$ = this.http.post(olbInprogressUrl, payload, {
                headers: header,
                observe: "response",
            });
        }
        const cacheKey = JSON.stringify({
            url: olbInprogressUrl,
            ...payload,
        });
        return olbInprg$.pipe(
            map((response) => {
                this.API_CACHE.set(cacheKey, response);
                return response.body;
            })
        );
    }

    async callCsForEcif() {
        //await this.initiateSession();
        this.config = this.configService.getConfig();
        let olbInprogressUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.INPROGRESS_OLB;
        if (this.config.CHANNELS_API.INPROGRESS_OLB_MOCK) {
            olbInprogressUrl = this.config.CHANNELS_API.INPROGRESS_OLB;
        } else {
            olbInprogressUrl =
                this.config.CHANNELS_API.CONTEXT_ROOT +
                this.config.CHANNELS_API.INPROGRESS_OLB;
        }
        let header = this.config.CHANNELS_API.INPROGRESS_OLB_HEADER;
        header = new HttpHeaders(header);
        const payload = {
            ecifId: Utils.getOLBUserEcifId(),
            subProduct: Utils.getSubProduct(),
            cardNo: Utils.getOLBUserCardNumber(),
        };
        // const payload = {
        //        "ecifId":"585858573648474784848585485859",

        //         "subProduct":"",

        //         "cardNo":"123"
        // };
        let olbInprg$: Observable<HttpResponse<Object>>;
        if (this.config.CHANNELS_API.INPROGRESS_OLB_MOCK) {
            olbInprg$ = this.http.get(olbInprogressUrl, {
                headers: header,
                observe: "response",
            });
        } else {
            olbInprg$ = this.http.post(olbInprogressUrl, payload, {
                headers: header,
                observe: "response",
            });
        }
        const cacheKey = JSON.stringify({
            url: olbInprogressUrl,
            ...payload,
        });
        return olbInprg$.subscribe((response) => {
            this.API_CACHE.set(cacheKey, response);
            return response.body;
        });
    }

    retrieveApplicationOLB(workflowId, ilNum) {
        this.config = this.configService.getConfig();
        const retrieveAppUrlRef =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.RETRIEVE_OLB_URIREF +
            "/" +
            ilNum;

        let headers = this.config.CHANNELS_API.RETRIEVE_OLB_HEADERREF;

        headers["workflow_id"] = workflowId;
        headers = new HttpHeaders(headers);

        return this.http.get(retrieveAppUrlRef, {
            headers: headers,
            observe: "response",
        });
    }
    deleteApplicationOLB(workflowId, ilNum) {
        this.config = this.configService.getConfig();
        const deleteAppUrlRef =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.DELETE_INPROGRESS_OLB +
            ilNum;
        let headers = this.config.CHANNELS_API.DELETE_INPROGRESS_OLB_HEADER;
        headers = new HttpHeaders(headers);
        const payload = {};
        return this.http.post(deleteAppUrlRef, payload, {
            headers: headers,
            observe: "response",
        });
    }


    save(payload: any) {
        // Set page status
        
//uncomment the below code once tested in dit

      /*  if(this.isFirstSaveInnvocation){
            payload['pageStatus']['initSaveRequest'] = true;
            this.isFirstSaveInnvocation = false;
        } else {
            delete payload?.pageStatus?.initSaveRequest;
        }*/

        this.setPageStatus();
        this.config = this.configService.getConfig();
        const saveUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.SAVE_URI;
        let header = this.config.CHANNELS_API.SAVE_HEADER;
        // header['workflow_id'] = this.createOlbWorkflowID ? this.createOlbWorkflowID : Utils.getOLBInprogressWorkflowID();
        header = new HttpHeaders(header);
        return this.http
            .post(saveUrl, payload, { headers: header, observe: "response" })
            .toPromise()
            .then((response) => {
                this.appResponse = response.body;
                Utils.setapplicationID(this.appResponse.applicationId);
                Utils.setApplicationData(this.appResponse);
                localStorage.setItem("appId", this.appResponse.applicationId);

                // console.log(" Utils.getapplicationID() app service", Utils.getapplicationID());
                if ((Utils.getIsOLBUser() || Utils.getIsJourneyUser()) && !Utils.getIsInSession()) {
                    const isUserInSession: boolean = JSON.parse(
                        response.headers.get("isinsession")
                    );
                    Utils.setIsInSession(isUserInSession);
                    Utils.isSessionInitiated = true;
                }
                this.applicationNumber$.next(
                    this.appResponse.applicationNumber
                );
                return response;
            })
            .catch((e) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "Save",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });

                return observableThrowError(e);
            });

    }

    getFinancialInstitutionCodes(lang) {
        this.config = this.configService.getConfig();
        const extraParamsForLang = "-ca";
        // defaulting language to english in case if requester could not send language parameter
        if (lang === undefined || lang === null) {
            lang = Utils.getLanguage() + extraParamsForLang;
        } else {
            lang = lang + extraParamsForLang;
        }
        const refUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT_MICROSERVICE +
            this.config.CHANNELS_API.RETRIEVE_INSTITUTION_CODE_URI;
        let header = this.config.CHANNELS_API.REFERENCE_HEADER;
        header = new HttpHeaders(header);
        //  return this.http.get(refUrl, { params: { partialId: '0', lang: lang }, headers: header })
        return this.http
            .get(refUrl, { params: { lang: lang }, headers: header })
            .toPromise()
            .then((response) => {
                Utils.setFinancialInstitutions(response);
                return response;
            })
            .catch((e) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "getFinInsCodes",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return observableThrowError(e);
            });
    }

    branchSetupSave(payload: any) {
        this.config = this.configService.getConfig();
        const branchAssistedUri =
            this.config.SESSION_API.CONTEXT_ROOT +
            this.config.SESSION_API.SETUP_URI;
        let header = this.config.SESSION_API.HEADER;
        header = new HttpHeaders(header);
        return this.http.post(branchAssistedUri, payload, { headers: header });
        //return this.http.get(branchAssistedUri,{ headers: header, observe: 'response' })

        // .then(response => {
        //     this.redirectURL = response;
        //     console.log("redirect uri",this.redirectURL);
        //     //this.redirectURL = response;
        //     return this.redirectURL;
        // })
        // .catch(e => {
        //     return observableThrowError(e);
        // });
    }

    uploadDoc(document: any, docName: string, fileType: string) {
        this.config = this.configService.getConfig();
        const docUploadUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT_2 +
            this.config.CHANNELS_API.UPLOADDOC_URI +
            "/" +
            docName;
        let header = this.config.CHANNELS_API.UPLOADDOC_HEADER;
        if (fileType) {
            header["Document-Extension"] = fileType.split("/")[1];
        }
        header = new HttpHeaders(header);
        return this.http
            .post(docUploadUrl, document, {
                headers: header,
                responseType: "text",
            })
            .pipe(retry(2))
            .toPromise()
            .then((response) => {
                this.appResponse = response;
                localStorage.setItem("appId", this.appResponse.applicationId);
                return response;
            })
            .catch((e) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "uploadDoc",
                    apiCallResponseCode: e.error?.errorInfo
                        ? e.status?.toString() +
                        "-" +
                        e.error?.errorInfo.errorCode.toString()
                        : e.status?.toString(),
                });
                return observableThrowError(e);
                // return Observable.throw(e);
                // return this.http.get('assets/data/virus-scan.mock.json')
                //     .toPromise()
                //     .then(response => {
                //         // this.appRetrieve = response;
                //         return response;
                //     });
            });
    }

    getQueryParams(name) {
        this.queryParams = new Map();
        const href = '$' + window.location.href;
        const startIndex = href.indexOf('?');
        if (startIndex > -1) {
            const hashes = href.slice(startIndex + 1).split('&');
            for (const value of hashes) {
                const pairs = value.split('=');
                if (!this.queryParams.hasOwnProperty(pairs[0])) {
                    this.queryParams[pairs[0]] = pairs[1];
                }
            }
        }
        if (name) {
            return this.queryParams[name] || null;
        }
        return this.queryParams;
    }

    initiateSession(skipInSession = false) {
        this.config = this.configService.getConfig();

        const sessionUrl =
            this.config.SESSION_API.CONTEXT_ROOT +
            this.config.SESSION_API.SETUP_URI;
        const header = this.config.SESSION_API.HEADER;

        return this.http
            .get(sessionUrl, {
                headers: header,
                responseType: "text",
                observe: "response",
            })
            .toPromise()
            .then((res) => {
                /**
                 * SA New requirement: MWB-17784
                 * UI will call checkOwnership, olbinprogress no matter the isinsession flag is true or false for existing BIL customer SSO flow.
                 * Implementation for above request:
                 * BILJ SSO CS treat notInsession Value always as false even if its true, so all the required API calls will be triggered with minimal code change.
                 * skipInSession - this attribute is used to achieve this
                 */

                let isInSession = skipInSession ? false : JSON.parse(res.headers.get("isinsession"));
                const isBranchAssisted = !!JSON.parse(
                    res.headers.get("isbranchassisted")
                );

                Utils.setIsBranchAssisted(isBranchAssisted);
                Utils.setIsBranchReferral(isBranchAssisted);

                // console.log('Is In Session', isInSession)

                if (isInSession == true) {
                    Utils.setIsInSession(true);
                    if (this.config.BRANCH_ASSISTED !== "true") {
                        Utils.isSessionInitiated = true;
                        // this.continue('technical-error-session', this.route.queryParams['lang']);
                        this.hidePageUndloadPopUp$.next(false);
                        this.onSignOut();
                    }
                } else {
                    Utils.setIsInSession(false);
                }

                window.sessionStorage.setItem("InSession", "true");

                if (
                    this.config.BRANCH_ASSISTED == "true" ||
                    isInSession != true
                ) {
                    this.getReferences(this.route.queryParams["lang"]);
                }

                if (isInSession === true) {
                    this.setSignOutLink(true);
                } else if (isInSession === false) {
                    this.setSignOutLink(false);
                }

                Utils.isSessionInitiated = true;
            })
            .catch((ex) => {
                this.analyticsService.setapiCallDetails({
                    apiCallMade: "true",
                    apiCallType: "initiateSession",
                    apiCallResponseCode: ex.error?.errorInfo
                        ? ex.status?.toString() +
                        "-" +
                        ex.error?.errorInfo.errorCode.toString()
                        : ex.status?.toString(),
                });
                // console.log(ex);
            });
    }

    retrieveApplication() {
        this.config = this.configService.getConfig();
        const retrieveAppUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.RETRIEVE_APPLICATION_URI;
        let headers = this.config.CHANNELS_API.RETRIEVE_APPLICATION_HEADER;
        // console.log("Utils.getIsInSession()---", Utils.getIsInSession());
        // console.log("localStorage.getItem('appId')--", localStorage.getItem('appId'));

        // For testing purpose on Local
        /*if (Utils.getIsInSession() === true && !Utils.nullCheck(localStorage.getItem('appId'))) {
            headers['workflow_id'] = Utils.getApplicationObject().applicationId;
        } else {
            headers['workflow_id'] = localStorage.getItem('appId');
        }*/

        // Utils.log(headers);
        // headers['X-BMO-REQUEST-ID'] =  '23796-' + Utils.getUUID();
        if (sessionStorage.getItem('amlFlag') !== undefined && sessionStorage.getItem('amlFlag') !== null) {
            headers['amlFlag'] = sessionStorage.getItem('amlFlag');
        }
        headers = new HttpHeaders(headers);
        return this.http.get(retrieveAppUrl, { headers: headers }) || of({});
        /*
            .toPromise()
            .then(response => {
                this.appRetrieve = response;
                // this.applicationNumber = this.appRetrieve.applicationNumber;
                //  Utils.log(response);

                // console.log("retrieve application url response data--", this.appRetrieve);

                if (this.appRetrieve.preferredLanguage == LANGUAGES.FRENCH) {
                    Utils.setLanguage('fr');
                } else { // default
                    Utils.setLanguage('en');
                }

                return response;
            })
            .catch(e => {
                return observableThrowError(e);

                // return this.http.get('assets/data/retrieveApplication.mock.json')
                //     .toPromise()
                //     .then(response => {
                //         this.appRetrieve = response;
                //         return response;
                //     });
            });
            */
    }

    getPackageSignInStatus(applicationId): Observable<any> {
        this.config = this.configService.getConfig();
        const getPackageSignInStatusURL =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.PACKAGE_SIGN_IN_STATUS_URI;
        let headers = this.config.CHANNELS_API.PACKAGE_SIGN_IN_STATUS_HEADER;
        // headers['workflow_id'] = applicationId;
        headers = new HttpHeaders(headers);
        return this.http.get(getPackageSignInStatusURL, {
            headers: headers,
            observe: "response",
        });
    }

    getApplicationObject(lang, applicationNumber, applicationPwd) {
        this.config = this.configService.getConfig();
        const retrieveAppUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.RETRIEVE_APPLICATION_URI +
            "abc";
        let headers = this.config.CHANNELS_API.RETRIEVE_APPLICATION_HEADER;
        headers["workflow_id"] = localStorage.getItem("appId");
        // Utils.log(headers);
        // headers['X-BMO-REQUEST-ID'] =  '23796-' + Utils.getUUID();

        headers = new HttpHeaders(headers);
        return this.http
            .get(retrieveAppUrl, { headers: headers })
            .toPromise()
            .then((response) => {
                // this.appRetrieve = response;
                Utils.setUserLoggedIn(true);
                Utils.setApplicationObject(response);
                //  Utils.log(response);
                return response;
            })
            .catch((e) => {
                //return observableThrowError(e);

                return this.http
                    .get("assets/data/getApplicationObject.mock.json")
                    .toPromise()
                    .then((response) => {
                        const res = response;
                        // this.appRetrieve = response;
                        Utils.setUserLoggedIn(true);
                        Utils.setApplicationObject(res);
                        return res;
                    });
            });
    }

    autoSave(currentForm, nextComponentUrl) {
        // Method is called to Save the current form and even if there save call is failed , navigate to the next page
        if (
            this.isAutoSaveEnabled === true &&
            Utils.getIsBranchAssisted() !== true &&
            Utils.getIsInSession() !== true
        ) {
            Utils.setEnableLoader(false);
            this.save(this.formService.getPayload())
                .then((res: any) => { })
                .catch((err) => {
                    console.log(err);
                });
            this.saveAndcontinue(currentForm, nextComponentUrl);
        }
    }

    retrieveApplicationOnRefresh() {
        this.config = this.configService.getConfig();
        const retrieveAppUrlRef =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.RETRIEVE_APPLICATION_URIREF;
        // console.log(retrieveAppUrlRef);
        let headers = this.config.CHANNELS_API.RETRIEVE_APPLICATION_HEADERREF;
        // console.log(headers);

        // headers['workflow_id'] = localStorage.getItem('appId');
        headers = new HttpHeaders(headers);
        // console.log(headers);

        return this.http.get(retrieveAppUrlRef, {
            headers: headers,
            observe: "response",
        });
        // .toPromise()
        // .then(response => {
        //     if (response.status === SUCCESS_RESPONSE) {
        //         this.applicationObject = response.body;

        //         //  Utils.log(response);
        //         return this.applicationObject;
        //     }
        // })
        // .catch(e => {
        //     return observableThrowError(e);

        //     // return this.http.get('assets/data/retrieveApplication.mock.json')
        //     //     .toPromise()
        //     //     .then(response => {
        //     //         this.appRetrieve = response;
        //     //         return response;
        //     //     });
        // });
    }

    getApplicationID() {
        if (Utils.getIsInSession() === true && this.appResponse === undefined) {
            return Utils.getApplicationObject().applicationId;
        } else {
            return this.appResponse.applicationId;
        }
    }

    openDialog(dialog: ViewContainerRef, component: any, options: any) {
        dialog.clear();
        const factory = this.resolver.resolveComponentFactory(component);
        this.ref = dialog.createComponent(factory);
        this.ref.instance.dialogData = options.dialogData
            ? options.dialogData
            : {};
        if (this.ref.instance.close) {
            this.ref.instance.close.subscribe((close) => {
                this.closeDialog();
            });
        }
        if (this.ref.instance.submit) {
            this.ref.instance.submit.subscribe((res) => {
                options.submit(res);
            });
        }
    }

    closeDialog() {
        this.ref.destroy();
    }

    public cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(";")
                .map((cookie) => cookie.split("="))
                .reduce(
                    (acc, [key, value]) => ({ ...acc, [key.trim()]: value }),
                    {}
                );
            return cookies[item];
        },
        setItem: (item, value, expires?) => {
            if (expires) {
                document.cookie = `${item}=${value};expires=${expires}`;
            } else {
                document.cookie = `${item}=${value};`;
            }
        },
    };

    checkNetworkRSM() {
        this.config = this.configService.getConfig();
        const checkNetworkUrlRef =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.CHECK_NETWORK_URI;
        let headers = this.config.CHANNELS_API.CHECK_NETWORK_URI_HEADER;
        // headers['True-Client-IP'] = '198.96.180.245'; // local testing
        headers = new HttpHeaders(headers);
        return this.http.get(checkNetworkUrlRef, {
            headers: headers,
            observe: "response",
        });
    }
    populateSetExpectationForm() {
        this.populateSetExpectationForm$.next(true);
    }

    triggerApplicationResumed() {
        this.applicationResumed$.next(true);
    }
    marginCancelDowngrade(response) {
        this.marginCancelDowngradeModal$.next(response);
    }
    setNonRegUnselected(val: boolean) {
        this.nonRegUnselected$.next(val);
    }
    checkHasTradingKnowledge(investmentKnowledgeLevel: any) {
        if (
            investmentKnowledgeLevel == this.investmentKnowledgeLevel.Limited ||
            investmentKnowledgeLevel ==
            this.investmentKnowledgeLevel.Knowledgeable ||
            investmentKnowledgeLevel == this.investmentKnowledgeLevel.Expert
        ) {
            this.setHasTradingKnowledge(true);
        } else if (
            investmentKnowledgeLevel == this.investmentKnowledgeLevel.None
        ) {
            this.setHasTradingKnowledge(false);
        }
    }

    printSaveCAA() {
        this.config = this.configService.getConfig();
        const printSaveCAAUrl =
            this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.PRINT_CAA_DOCUMENT;
        let header = this.config.CHANNELS_API.PRINT_CAA_DOCUMENT_HEADER;
        header['workflow_id'] = Utils.applicationID;
        header = new HttpHeaders(header);
        return this.http
            .post(printSaveCAAUrl, "", {
                headers: header,
                observe: "response",
                responseType: "arraybuffer",
            })
            .toPromise()
            .then(response => {
                return response;
            })
            .catch((e) => {
                return observableThrowError(e);
            });
    }
    gotoSetExpectationOrSelectAccount(nextComponentUrl, lang, subProduct) {
        let navParameter: NavigationExtras = {};
        const promoCode = sessionStorage.getItem("promoCode");
        if (promoCode) {
            if (subProduct) {
                navParameter = {
                    queryParams: {
                        lang: lang,
                        subProduct: subProduct,
                        promocode: promoCode,
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: lang,
                        promocode: promoCode,
                    },
                };
            }
        } else {
            if (subProduct) {
                navParameter = {
                    queryParams: {
                        lang: lang,
                        subProduct: subProduct,
                    },
                };
            } else {
                navParameter = {
                    queryParams: {
                        lang: lang,
                    },
                };
            }
        }

        this.router.navigate(["/" + nextComponentUrl], navParameter);
    }

    public getAllAccounts() {
        return Utils.getApplicationData()?.accounts || [];
    }

    public isMultiAccountAndMarginApp() {
        let isMultiAccountAppAndMargin: boolean = false;
        let accountsInApp: any = this.getAllAccounts();
        if ((accountsInApp || []).length > 1) {
            accountsInApp.forEach((account) => {
                if (account.type == ACCOUNT_TYPE.MARGINACCOUNT && account.subTypes[0] == "2") {
                    isMultiAccountAppAndMargin = true;
                }
            });
        }
        return isMultiAccountAppAndMargin;
    }

    public isMargin() {
        let isMargin: boolean = false;
        let accountsInApp: any = this.getAllAccounts();
        if ((accountsInApp || []).length > 0) {
            accountsInApp.forEach((account) => {
                if (account.type == ACCOUNT_TYPE.MARGINACCOUNT && account.subTypes[0] == "2") {
                    isMargin = true;
                }
            });
        }
        return isMargin;
    }
    public isCashAccount() {
        let isCash: boolean = false;
        let accountsInApp: any = this.getAllAccounts();
        if ((accountsInApp || []).length > 0) {
            accountsInApp.forEach((account) => {
                if (account.type == ACCOUNT_TYPE.CASH && account.subTypes[0] == "1") {
                    isCash = true;
                }
            });
        }
        return isCash;
    }

    //below function get call from docu upload component to set doc upload status to true from UI only
    // to get docuupload status from UI as from DL it take time to reflect the status;
    setDocumentUploadStatus(value) {
        this.docuUploadStatus = value;
    }
    getDocumentUploadStatus() {
        return this.docuUploadStatus;
    }

    getCAUSExcludedContryList() {
        let countryList = Utils.getCountriesList();
        if (countryList && (this.getCRAtaxresidencyEnabledFeatureFlag() || this.getCaaTaxResidencyCertificateFlag())
            && (Utils.isCash() || Utils.isMargin())) {
            countryList = countryList.filter(x => [COUNTRY_CODE.CANADA, COUNTRY_CODE.USA].indexOf(x.code) < 0);
        }
        return countryList
    }
    checkSDOwnership() {
        this.config = this.configService.getConfig();
        const checkOwnershipUrl = this.config.CHANNELS_API.CONTEXT_ROOT +
            this.config.CHANNELS_API.CHECK_OWNERSHIP;

        let header = this.config.CHANNELS_API.CHECK_OWNERSHIP_HEADER;
        header = new HttpHeaders(header);
        const payload = {
            ecifId: Utils.getOLBUserEcifId() ?? Utils.getJourneyUserEcifId(),
            subProduct: Utils.getSubProduct(),
            cardNo: Utils.getOLBUserCardNumber(),
            userId: Utils.getJourneyUserLoginId()
        };

        return this.http.post(checkOwnershipUrl, payload, { headers: header });

    }

    goToLoginPage(client) {
        const locationOrigin = window.location.origin;
        const lang = Utils.getLanguage();
        const subProduct = Utils.getSubProduct() === productTypeAD ? productTypeAD : productTypeSD;
        const env: any = {
            production: true,
            oauthCallbackUrl: locationOrigin + '/wealth/onboard/onlineinvesting/?lang=' + lang + '&subProduct=' + subProduct + '&dest=' + client
        };
        const state = this.strRandom(40);
        const codeVerifier = this.strRandom(128);
        const cryptoJS: any = (window as any).CryptoJS;
        const codeVerifierHash = cryptoJS.SHA256(codeVerifier).toString(cryptoJS.enc.Base64);
        const codeChallenge = codeVerifierHash
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');

        if (client === CDB) {
            this.hidePageUndloadPopUp$.next(false);
            env.oauthLoginUrl = OLB_AUTHORIZE_URL;
            env.oauthTokenUrl = locationOrigin + '/oauth/token';
            env.oauthClientId = OLB_CDB_CLIENT_ID;
            window.sessionStorage.setItem('InSession', 'false');
        } else {
            env.oauthClientId = JOURNEY_CLIENT_ID;
            env.oauthLoginUrl = this.getOrigin(document.referrer) + AUTHORIZE_URL;
        }
        localStorage.setItem('state', state);
        localStorage.setItem('codeVerifier', codeVerifier);

        const params = [
            'client_id=' + env.oauthClientId,
            'redirect_uri=' + encodeURIComponent(env.oauthCallbackUrl),
            'response_type=code',
            'state=' + state,
            'code_challenge=' + codeChallenge,
            'code_challenge_method=S256',
            'lang=' + lang
        ];
        if(client === CDB) {
            params.push('response_mode=form_post')
        }
        if (client === BILJ && (window.location.href.includes('localhost') || window.location.href.includes('dit'))) { //check and redirect accordingly
            // Utils.setJourneyUserEcifId(this.getQueryParams('ecifID'));
            this.router.navigate(['/journey/login'], {
                queryParams: {
                    response_type: 'code',
                    code_challenge_method: 'S256',
                    code_challenge: codeChallenge,
                    client_id: env.oauthClientId,
                    redirect_uri: encodeURIComponent(env.oauthCallbackUrl),
                    scope: 'openId',
                    state: state,
                    lang
                }
            });
        } else {
            /**
             * Reason for adding the delay
             * When same environment is open in two diffrent tabs or IL user tried to click open an account multiple times in the same browser
             * The technical error page is loaded with a flicker, so adding this delay to fix the flicker.
             * Below line of code will unhook and rehook the application thats the reason for flicker, so stopping it happen.
             */
            setTimeout(()=>{
                if(sessionStorage.getItem("blockJourneyAnotherTabOpen") !== "true") {
                    window.location.href = env.oauthLoginUrl + '?' + params.join('&');
                }
            }, 1000);
        }
        // console.log(client + ' ' + this.storage.get('state') + ' ' + this.storage.get('state'));
    }

    private strRandom(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public navigateToJourneys(){
        if(document.referrer.includes('dit') || document.referrer.includes('qa')){
            window.location.replace(`https://investorlineqa05.bmofg.com/ILClientWeb/login/DisplayLogin.jsp?refresh=true&lang=E`);
        } else {
            window.location.replace(`https://www.bmoinvestorline.com/ILClientWeb/login/DisplayLogin.jsp?refresh=true&amp;lang=E`);
        }
    }

    private getOrigin(url: string): string {
        try {
            return new URL(url).origin;
        } catch(e) {
            return "";
        }
    }

    public setOLbJourneyStatus(isJourney, isOlbUser) {
        isJourney = !!isJourney && Utils.getIsSDOwner() !== false;
        isOlbUser = !!isOlbUser;
        if(isJourney) {
            this.resetOLbStatus();
            Utils.setIsJourneyUser(true);
            Utils.setIsIlUser(true);
            this.isJourneysUser$.next(true);
            this.formService.getPayload().setIlUser(true);
        } else if(isOlbUser) {
            this.resetJourneyStatus();
            Utils.setIsOLBUser(true);
        }
        /**
         * olbUser flag should be true in case of OLB or journey
         */
        this.formService.getPayload().setOlbUser(isJourney || isOlbUser);
    }

    public resetOlBJourneyStatus() {
        this.resetOLbStatus();
        this.resetJourneyStatus();
    }

    private resetJourneyStatus(){
        Utils.setIsJourneyUser(false);
        Utils.setIsIlUser(false);
        this.isJourneysUser$.next(false);
        this.formService.getPayload().setIlUser(false);
    }

    private resetOLbStatus() {
        Utils.setIsOLBUser(false);
        this.formService.getPayload().setOlbUser(false);
    }
}
