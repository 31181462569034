/**
 * @updated: Hari (26th April)
 */

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import $ from 'jquery';
import { APP_CONFIG, EMPLOYMENTSTATUS, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX } from '../../config/app-config.constants';
import { EmploymentInfoService } from '../../shared/service/employment-info.service';
import { inject } from '@angular/core/testing';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { APP_ROUTES } from '../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../shared/utils/utils.class';
import { ContactInformation } from '../contact-information/contact-information.component';

import { Subscription } from 'rxjs';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { PrefillService } from '../../shared/service/prefill.service';


export class EmploymentInformation {
    employmentStatus: any = null;
    selectOccupation: any = null;
    selectPreviousOccupation: any = null;
    selectNatureOfBusiness: any = null;
    selectCountry: any = null;
    unit: any = '';
    city: any = '';
    province: any = null;
    state: any = null;
    streetNumberName: any = '';
    postalCode: any = '';
    zipCode: any = '';
    employerName: any = '';
    jobTitle: any = '';
    bmoGroupOption: any = null;
    isBMOEmployee: any = null;
}


@Component({
    selector: 'app-employment-information',
    templateUrl: './employment-information.component.html',
    providers: [EmploymentInfoService]
})
export class EmploymentInformationComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('employmentDetailsForm', {static: true}) employmentDetailsForm: NgForm;

    // For accessibility purposes
    @ViewChild('selectEmploymentStatusCtrl', {static: true}) selectEmploymentStatusCtrl: UntypedFormControl;
    @ViewChild('selectOccupationCtrl', {static: true}) selectOccupationCtrl: UntypedFormControl;
    @ViewChild('jobTitle', {static: true}) jobTitle: UntypedFormControl;
    @ViewChild('selectPreviousOccupationCtrl', {static: true}) selectPreviousOccupationCtrl: UntypedFormControl;
    @ViewChild('selectNatureOfBusinessCtrl', {static: true}) selectNatureOfBusinessCtrl: UntypedFormControl;
    @ViewChild('employerNameCtrl', {static: true}) employerNameCtrl: UntypedFormControl;
    @ViewChild('selectCountryCtrl', {static: true}) selectCountryCtrl: UntypedFormControl;
    @ViewChild('streetNumberNameCtrl', {static: true}) streetNumberNameCtrl: UntypedFormControl;
    @ViewChild('unitCtrl', {static: true}) unitCtrl: UntypedFormControl;
    @ViewChild('cityCtrl', {static: true}) cityCtrl: UntypedFormControl;
    @ViewChild('provinceCtrl', {static: true}) provinceCtrl: UntypedFormControl;
    @ViewChild('postalCodeCtrl', {static: true}) postalCodeCtrl: UntypedFormControl;
    @ViewChild('stateCtrl', {static: true}) stateCtrl: UntypedFormControl;
    @ViewChild('zipCodeCtrl', {static: true}) zipCodeCtrl: UntypedFormControl;


    public employmentInformation: EmploymentInformation;
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public employmentDetails: any;
    public employmentStatus: any;
    public currentNatureOfBusiness: any;
    public applicant: any;
    public currentCountry: any;
    public currentOccupation: any;
    public previousOccupation: any;
    public provinces: any;
    public states: any;
    public isFormSubmit: boolean;
    public bmoGroupOptions: any;
    public isBMOEmployee: boolean;
    public NOTPRESENTLYEMPLOYED: string;
    public RETIRED: string;
    public HOMEMAKER: string;
    public STUDENT: string;
    public UNEMPLOYED: string;
    public COUNTRYCANADA: string;
    public COUNTRYUSA: string;
    public canPostControl = null;
    public disableFinishLater;
    public showFinishLater;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    public APP_ROUTES = APP_ROUTES;
    public isAMLFunctionalityEnabled:boolean = false
    public isDataRationalizationEnabled: boolean = false;
    public isEmploymentDetailsRetired: Boolean = false;
    public url: any;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private onExitApplicationPerformSaveSubscriber;
    public isShowPreOccAndNatureOfBusiness: Boolean;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private formService: FormService,
        private empInfoService: EmploymentInfoService,
        private winRef: WindowRefService,
        private analytics: AnalyticsService,
        private prefillService: PrefillService) {

        this.employmentInformation = new EmploymentInformation();
        this.isFormSubmit = false;
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();

        this.employmentStatus = this.empInfoService.getEmploymentStatusOptions();
        this.currentOccupation = this.empInfoService.getOccupationOptions();
        this.currentOccupation = this.currentOccupation
            .filter((item) => item.expired === undefined || item.expired === false);


        this.previousOccupation = this.empInfoService.getOccupationOptions();
        this.previousOccupation = this.previousOccupation
            .filter((item) => item.expired === undefined || item.expired === false);

        this.currentNatureOfBusiness = this.empInfoService.getNatureOfBusinessOptions();

        this.currentCountry = this.empInfoService.getCountryOptions();
        this.provinces = this.empInfoService.getProvinceOptions();
        this.states = this.empInfoService.getStateOptions();
        this.bmoGroupOptions = this.empInfoService.getBMOGroupOptions();

        this.NOTPRESENTLYEMPLOYED = EMPLOYMENTSTATUS.NOTPRESENTLYEMPLOYED;
        this.RETIRED = EMPLOYMENTSTATUS.RETIRED;
        this.HOMEMAKER = EMPLOYMENTSTATUS.OCCUPATION.HOMEMAKER;
        this.STUDENT = EMPLOYMENTSTATUS.OCCUPATION.STUDENT;
        this.UNEMPLOYED = EMPLOYMENTSTATUS.OCCUPATION.UNEMPLOYED;
        this.COUNTRYCANADA = EMPLOYMENTSTATUS.COUNTRY.CANADA;
        this.COUNTRYUSA = EMPLOYMENTSTATUS.COUNTRY.USA;
        this.formService.forms.employmentInformation.isVisited = true;
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                // console.log(' getPartialSave item: ', item);
                if (item === 'employment') {
                    this.onFinishLater();
                }

            });
        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        });

        this.formService.setActiveComponent('employmentInformation', this);

        this.url = '/' + APP_ROUTES.employment_information;
    }

    ngOnInit() {
        this.isAMLFunctionalityEnabled = this.applicationService.getAMLFunctionalityFlag();
        // console.log("this.currentOccupation",this.currentOccupation);
        // console.log("this.  this.previousOccupation",  this.previousOccupation);

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        if((Utils.getIsOLBUser() || Utils.getIsJourneyUser() ) && !Utils.getIsBranchAssisted() && !this.formService.forms.employmentInformation.data){
            this.prefillService.getEmployemntInfo();
        }

        this.isEmploymentDetailsRetired = Utils.isEmploymentDetails();
        this.titleService.setTitle(Utils.getPageTitleEmploymentDetails());

        this.updateLabels();
        if (this.formService.forms.employmentInformation.data != null ) {
            this.employmentInformation = this.formService.getFormData('employmentInformation');
            // already visited form and now visiting back again
        } else {
            this.employmentInformation = new EmploymentInformation();  // coming to form for the first time
        }
        this.formService.setActiveForm(this.employmentDetailsForm);
        this.onEmployerNameChange();

        if(this.isEmploymentDetailsRetired) {
            this.showPrevOccAndNatureOfBusiness();
        }

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });


        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });
       this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();

        setTimeout(() => {
            this.setGTM();
        }, 200);

    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    showPrevOccAndNatureOfBusiness() {

        if(this.employmentInformation.employmentStatus == this.RETIRED && !this.isValueNull(this.employmentInformation.selectPreviousOccupation) && !this.isValueNull(this.employmentInformation.selectNatureOfBusiness)) {
            this.isShowPreOccAndNatureOfBusiness = true;
        } else {
            this.isShowPreOccAndNatureOfBusiness = false;
        }


    }
    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {

        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == 'clicked on ' + this.url) {

                if (this.employmentDetailsForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }

    setEmploymentStatus() {
        if(this.isAMLFunctionalityEnabled) {
            //added this condition to fix issue of passing country code when isAMLFunctionalityEnabled flag is off. this condition can be removed when feature functionality is not required
         this.employmentInformation.selectCountry = this.COUNTRYCANADA;  // commented for MWB-13882
        }
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {

        if (!this.employmentDetailsForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        if ((Utils.getIsOLBUser() || Utils.getIsJourneyUser())  && this.employmentDetailsForm.valid) {
            this.formService.forms.employmentInformation.isCompleted = true;
        }
        this.formService.setFormData('employmentInformation', this.employmentInformation);

        // To prepare payload with PI , CI, EI
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);

        // save current page if form is valid
        if (!this.formService.forms.employmentInformation.isCompleted) {
            if (Utils.getIsOLBUser() || Utils.getIsJourneyUser()) {
                this.prefillService.getEmployemntInfo();
            } else {
                const emptyEmployment = new EmploymentInformation();
                const emptyContact = new ContactInformation();
                this.formService.getPayload().setEmploymentInformation(emptyEmployment, emptyContact);
            }

        } else {
            if (this.employmentDetailsForm.valid) {
                this.formService.getPayload().setEmploymentInformation(this.employmentInformation, contactInformation);
            }
        }

        // console.log('payload with PI CI EI ', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });


    }

    saveOnSignOut() {
        this.resetComponentObjectAttr();
        this.formService.forms.employmentInformation.isCompleted = true;
        this.formService.setFormData('employmentInformation', this.employmentInformation);

        // To prepare payload with PI , CI, EI
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);

        // save current page if form is valid
        if (!this.formService.forms.employmentInformation.isCompleted) {
            const emptyEmployment = new EmploymentInformation();
            const emptyContact = new ContactInformation();
            this.formService.getPayload().setEmploymentInformation(emptyEmployment, emptyContact);
        } else {
            if (this.employmentDetailsForm.valid) {
                this.formService.getPayload().setEmploymentInformation(this.employmentInformation, contactInformation);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }


    onSubmit() {

        // testing
        // Utils.setIsInSession(true);

        // For accessibility purposes, set focus on the invalid element.
        if (!this.employmentDetailsForm.valid) {
            this.setFocus();
        }

        this.isFormSubmit = true;
        // validate the personalDetails here
        if (this.employmentDetailsForm.valid) {
            this.resetComponentObjectAttr();

            if(!this.isShowPreOccAndNatureOfBusiness && this.employmentInformation.employmentStatus == this.RETIRED && this.isEmploymentDetailsRetired) {
                this.employmentInformation.selectPreviousOccupation= null;
                this.employmentInformation.selectNatureOfBusiness = null;

            }
            this.formService.forms.employmentInformation.isCompleted = true;
            this.formService.setFormData('employmentInformation', this.employmentInformation);

            // To prepare payload with PI , CI, EI
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');

            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(this.employmentInformation, contactInformation);

            // console.log('payload with PI CI EI ', this.formService.getPayload());

            if (Utils.getIsInSession() === false) {

                this.applicationService.saveAndcontinue(this.employmentInformation, APP_ROUTES.family_information);

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {
                            this.applicationService.saveAndcontinue(this.employmentInformation, APP_ROUTES.family_information);
                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }
        } else {
            // console.log('employmentDetailsForm is invalid');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.employmentDetailsForm);
           }, 250);

    }

    updateLabels() {
        $('input, select').each(function (index) {
            if (($(this).val() !== '') && ($(this).val() !== null)) {
                $(this).next('label').addClass('active-label');
            } else {
                $(this).next('label').removeClass('active-label');
            }
        });
    }

    ngAfterViewInit() {

        // this.analytics.setState('step 6');
        this.analytics.setPageState(this.url);
        // this.analytics.setPPVID(ANALYTICS.employment_information);

        this.checkFormValidityOnSignOut();

        // this.analytics.setvPageTitle(Utils.getPageTitleEmploymentDetails());

        // this.analytics.setOriginalLocation(Utils.getPageTitleEmploymentDetails());

        // this.analytics.setvURL(Utils.getvURL());

    }

    /**
     * @param: none
     * @return: none
     * @description: Toggle BMO Group list dropdown based on "Employer Name" input change.
     * @description: if typed employer name matches any one from the array then show the field.
     */
    onEmployerNameChange() {
        if (this.employmentInformation.employerName !== undefined && this.employmentInformation.employerName !== null) {
            const employerNameListArr: Array<string> = APP_CONFIG.EMPLOYER_NAME_LIST;
            this.employmentInformation.employerName = this.employmentInformation.employerName.trim().replace(/  +/g, ' ');
            this.employmentInformation.isBMOEmployee = false;
            for (const employerName of employerNameListArr) {
                if (this.employmentInformation.employerName.toLowerCase().indexOf(employerName.toLowerCase().trim()) > -1) {
                    this.employmentInformation.isBMOEmployee = true;
                    break;
                }
            }
            if (!this.employmentInformation.isBMOEmployee) {
                this.employmentInformation.bmoGroupOption = null;
            }
        }
        if (this.employmentInformation.employerName === undefined) {
            this.employmentInformation.employerName = '';
        }
    }

    /**
     * @param: none
     * @return: none
     * @description: On country change reset address fields
     */
    onCountryChange(event) {

        //this.applicationService.resetAttributes(this.employmentInformation, ['streetNumberName', 'city', 'province', 'state', 'postalCode', 'zipCode', 'unit']);
        this.resetEmployeeAddressField()
    }

    resetEmployeeAddressField(){
        this.employmentInformation.province = null;
        this.employmentInformation.postalCode = null;
        this.employmentInformation.streetNumberName = null;
        this.employmentInformation.city = null;
        this.employmentInformation.unit = null;
        this.employmentInformation.zipCode = null;
    }

    /**
     * @param: none
     * @return: none
     * @description: On country change
     */
     onStreetNameChange(event) { // commented for MWB-13882
        if (this.employmentInformation.selectCountry !== this.COUNTRYCANADA &&
            this.employmentInformation.selectCountry !== this.COUNTRYUSA) {
            if (this.canPostControl) {
                 this.canPostControl.destroy();
                this.canPostControl = null;
            }
            return;
        }

        const { pca } = this.winRef.nativeWindow;
        const fields = [{
            element: event.target.id,
            field: 'Line1',
            mode: pca.fieldMode.SEARCH
        }];
        const MaxSuggestions = 50;
        const MaxResults = 300;
        const minItems = 1;
        const maxItems = 10;
        const options = {
            key: this.applicationService.getCanadaPostKey(),
            maxItems: maxItems,
            minItems: minItems,
            search: {
                maxSuggestions: MaxSuggestions,
                maxResults: MaxResults
            },
            bar: {
                showCountry: false,
                showLogo: false,
                logoLink: false
            },
            languagePreference: this.isCurrentLangEnglish ? 'en-US' : 'fr-CA'
        };

        // don't create new pca.Address it will create multiple popups
        if (!this.canPostControl) {
            this.canPostControl = new pca.Address(fields, options);
        }

        this.canPostControl.setCountry(
            this.employmentInformation.selectCountry === this.COUNTRYCANADA ? 'CA' : 'US'
        );

        this.canPostControl.listen('populate', (address) => {

            if (this.employmentInformation.selectCountry !== this.COUNTRYCANADA &&
                this.employmentInformation.selectCountry !== this.COUNTRYUSA) {
                return;
            }

            let provinceData;
            if (this.employmentInformation.selectCountry === this.COUNTRYUSA) {
                provinceData = this.states.find(obj => obj.isoCode === address.ProvinceCode);
            } else {
                provinceData = this.provinces.find(obj => obj.isoCode === address.ProvinceCode);
            }

            this.employmentInformation.streetNumberName = address.Line1;
            this.employmentInformation.city = address.City;
            this.employmentInformation.province = provinceData.code;
            this.employmentInformation.postalCode = address.PostalCode;

            if (this.employmentInformation.selectCountry === this.COUNTRYUSA) {
                this.employmentInformation.state = provinceData.code;
                this.employmentInformation.zipCode = address.PostalCode;
            }
        });
    }

    /**
     * @param: none
     * @return: none
     * @description: Reset attributes of employee info object based on employment status
     */
    resetComponentObjectAttr() {
        const attrNotToBeResetArr: Array<string> = ['selectCountry', 'employmentStatus'];
        if (this.employmentInformation.employmentStatus === this.RETIRED ||
            this.employmentInformation.employmentStatus === this.NOTPRESENTLYEMPLOYED) {
            if (this.employmentInformation.employmentStatus === this.RETIRED) {
                attrNotToBeResetArr.push('selectPreviousOccupation');
                // if retired, and the occupation is homemark, student and unemployed selectNatureOfBusiness must be reset
                if ([this.HOMEMAKER, this.STUDENT, this.UNEMPLOYED].indexOf(this.employmentInformation.selectPreviousOccupation) < 0) {
                    attrNotToBeResetArr.push('selectNatureOfBusiness');
                }
            }
            this.applicationService.resetAttributes(this.employmentInformation, attrNotToBeResetArr);
        }
         if (this.employmentInformation.selectCountry === this.COUNTRYUSA) { // commented for MWB-13882
            this.employmentInformation.postalCode = '';
        }
        if (this.employmentInformation.selectCountry === this.COUNTRYCANADA) {
            this.employmentInformation.zipCode = '';
        }
    }
    setOccupation(event) {
        this.employmentInformation.selectOccupation = event ? event.code : null;
    }
    settNatureOfBusiness(event) {
       this.employmentInformation.selectNatureOfBusiness = event ? event.code : null;
    }
    setPreviousOccupation(event) {
        this.employmentInformation.selectPreviousOccupation = event ? event.code : null;
    }
    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('employmentInformation', this.employmentInformation);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleEmploymentDetails(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.EMPLOYMENT_INFO,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.employment_information,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log('employment details', this.winRef.nativeWindow.dataLayer);

    }

}




