/**
 * @purpose: Contact information
 * @ created:
 * @ last updated by: Hari Kori (26 April, 2018)
 * @created on:
 *
 */

import { Component, OnInit, ViewChild, OnDestroy, AfterViewChecked, AfterViewInit, EventEmitter, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { FormService } from '../../shared/service/form.service';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX, COUNTRY_CODE } from '../../config/app-config.constants';
import moment from 'moment';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../shared/utils/utils.class';
import { Subscriber, Observable, Subscription } from 'rxjs';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { PrefillService } from '../../shared/service/prefill.service';

export class ContactInformation {
    streetNumberName = '';
    unit = '';
    city = '';
    province = null;
    postalCode = '';
    addressNumberOfYears = null;
    previousResidenceLocation = null;
    primaryResidenceLocated = null;
    sameMailingAddress = null;
    diffAddressRequired = false;
    diffStreetNumberName = null;
    diffUnit = '';
    diffCity = null;
    diffProvince = null;
    diffPostalCode = null;
    phoneType = null;
    phoneNumber = '';
    phoneExtension = '';
    provinceLabel = null;
    diffProvinceLabel = null;
    trustedContactPersonRequired = null;
    trustedContactPerson = {
        isTrustedPerson: null,
        firstname: '',
        initial: '',
        lastname: '',
        country: '',
        streetNumberName: '',
        unit: '',
        city: '',
        province: null,
        postalCode: '',
        emailAddress: null,
        phoneNumberHome: null,
        phoneNumberOther: null,
        tcpRelationShip: null,
        isInternationalNumber: null
    }
}


@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html'
})

export class ContactInformationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('contactInformationForm', { static: true }) contactInformationForm: NgForm;

    // For accessibility purposes
    @ViewChild('phoneNumberTypeCtrl', { static: true }) phoneNumberTypeCtrl: UntypedFormControl;
    @ViewChild('phoneNumberCtrl', { static: true }) phoneNumberCtrl: UntypedFormControl;
    @ViewChild('phoneExtensionCtrl', { static: true }) phoneExtensionCtrl: UntypedFormControl;
    @ViewChild('streetNumberNameCtrl', { static: true }) streetNumberNameCtrl: UntypedFormControl;
    @ViewChild('unitCtrl', { static: true }) unitCtrl: UntypedFormControl;
    @ViewChild('cityCtrl', { static: true }) cityCtrl: UntypedFormControl;
    @ViewChild('provinceCtrl', { static: true }) provinceCtrl: UntypedFormControl;
    @ViewChild('postalCodeCtrl', { static: true }) postalCodeCtrl: UntypedFormControl;
    @ViewChild('sameMailingAddressCtrl', { static: true }) sameMailingAddressCtrl: UntypedFormControl;
    @ViewChild('diffStreetNumberNameCtrl', { static: true }) diffStreetNumberNameCtrl: UntypedFormControl;
    @ViewChild('diffUnitCtrl', { static: true }) diffUnitCtrl: UntypedFormControl;
    @ViewChild('diffCityCtrl', { static: true }) diffCityCtrl: UntypedFormControl;
    @ViewChild('diffProvinceCtrl', { static: true }) diffProvinceCtrl: UntypedFormControl;
    @ViewChild('diffPostalCodeCtrl', { static: true }) diffPostalCodeCtrl: UntypedFormControl;
    @ViewChild('tcpStreetNumberNameCtrl', { static: true }) tcpStreetNumberNameCtrl: UntypedFormControl;
    @ViewChild('tcpUnitCtrl', { static: true }) tcpUnitCtrl: UntypedFormControl;
    @ViewChild('tcpCityCtrl', { static: true }) tcpCityCtrl: UntypedFormControl;
    @ViewChild('tcpProvinceCtrl', { static: true }) tcpProvinceCtrl: UntypedFormControl;
    @ViewChild('tcpPostalCodeCtrl', { static: true }) tcpPostalCodeCtrl: UntypedFormControl;
    @ViewChild('tcpZipCodeCtrl', { static: true }) tcpZipCodeCtrl: UntypedFormControl;
    @ViewChild('trustedPersonCtrl', { static: true }) trustedPersonCtrl: UntypedFormControl;
    @ViewChild('tcpfirstNameCtrl', { static: true }) tcpfirstNameCtrl: UntypedFormControl;
    @ViewChild('tcpLastNameCtrl', { static: true }) tcpLastNameCtrl: UntypedFormControl;
    @ViewChild('selectTcpCountryCtrl', { static: true }) selectTcpCountryCtrl: UntypedFormControl;
    @ViewChild('phoneNumberHomeCtrl', { static: true }) phoneNumberHomeCtrl: UntypedFormControl;
    @ViewChild('phoneNumberOtherCtrl', { static: true }) phoneNumberOtherCtrl: UntypedFormControl;
    @ViewChild('tcpEmailAddressCtrl', { static: true }) tcpEmailAddressCtrl: UntypedFormControl;
    @ViewChild('tcpProviceLabelCtrl', { static: false }) tcpProviceLabelCtrl: ElementRef;


    public contactInformation: ContactInformation = new ContactInformation();
    public isCurrentLangEnglish: boolean;
    public isCurrentLangFrench: boolean;
    public isFormSubmit = false;
    public provinces: any;
    public usProvinces: any;
    public phoneTypeArray: Array<string>;
    public phoneNumberCounter: any = 0;
    public maxAlternateNumbers: any = 3;
    public phoneNumbersArray: Array<number> = [0];
    public showFinishLater = true;
    public criticalError: Boolean = false;
    appNumber;
    public accountSetup;
    public APP_ROUTES = APP_ROUTES;
    public trustedPersonList: any;
    public references: any;
    public url: any;
    // public diffAddressRequired: boolean = false;
    public countries: any;
    public isCanada: boolean = false;
    public isUS: boolean = false;
    public saveOperation: boolean = true;
    public isMandatoryProject: boolean = true;
    public applicationsData: any;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public tcpProvinceObj = {};
    public tcpProvinceTranslate;
    public canPostControl = null;
    public prefillPostalCode = {
        softWarningMsg: false,
        postalCode: null
    }
    public isOlbUser = false;
    public isJourneyUser = false;
    public isSDOwner = false;
    private onExitApplicationPerformSaveSubscriber;
    public isDataRationalizationEnabled: boolean = false;

    constructor(public applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        public formService: FormService,
        private route: ActivatedRoute,
        private analytics: AnalyticsService,
        private renderer: Renderer2 ,
        private prefillService: PrefillService) {

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.formService.forms.contactInformation.isVisited = true;
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'contact') {
                    this.onFinishLater();
                }
            });
        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        });
        // this.diffAddressRequired = false;
        this.formService.setActiveComponent('contactInformation', this); // setting this component as active for data retention
        this.url = '/' + APP_ROUTES.contact_information;
    }

    ngOnInit() {
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();
        this.isJourneyUser = Utils.getIsJourneyUser();
        this.isSDOwner = Utils.getIsSDOwner();
        if((Utils.getIsOLBUser() || this.isJourneyUser) && !Utils.getIsBranchAssisted()){
            if(!this.formService.forms.contactInformation.data){
                this.prefillService.contactInfoPrefill();
            }
            if(!this.formService.forms.employmentInformation.data){
                this.prefillService.getEmployemntInfo();
            }
        }

       this.updateSignOutResume();

        this.trustedPersonList = [{
            code: true,
            label: "contactInformation.labels.Yes"
        }, {
            code: false,
            label: "contactInformation.labels.No"
        }]

        this.titleService.setTitle(Utils.getPageTitleContactInformation());


        this.applicationService.fetchReferences(this.route);
        if (this.applicationService.appReferences !== undefined) {
            this.loadProvince()//this.applicationService.appReferences.references.countries[0].stateProvinces;
            this.loadProvince(COUNTRY_CODE.USA);
            this.countries = this.applicationService.appReferences.references.countries;
            for (var i = 0; i < this.countries.length; i++) {
                const { code, stateProvinces } = this.countries[i];
                this.tcpProvinceObj[code] = stateProvinces;
            }
        }
        this.references = this.applicationService.appReferences.references;
        this.phoneTypeArray = [
            'contactInformation.labels.phoneMobile', 'contactInformation.labels.phoneHome',
            'contactInformation.labels.phoneWork'
        ];

        this.applicationsData = this.applicationService.getApplicationData();

        if (this.formService.forms.contactInformation.data != null ) {
            this.contactInformation = this.formService.getFormData('contactInformation');
            this.setTcp();
            if(!this.contactInformation.sameMailingAddress) { // MWB-14337
                this.contactInformation.sameMailingAddress = "Yes";
            }
        } else {
            this.contactInformation = new ContactInformation();  // coming to form for the first time
            // this.contactInformation.diffPostalCode='M2n5x7';
            this.contactInformation.sameMailingAddress = "Yes";  // MWB-14337
        }




        // watch for changes to the form field
        /*this.contactInformationForm.controls['province'].valueChanges.subscribe(value => {
        });*/

        //

        this.formService.setActiveForm(this.contactInformationForm);
        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });


        setTimeout(() => {
            this.setGTM();
        }, 200);

        if (this.contactInformation.trustedContactPerson.country === COUNTRY_CODE.CANADA) {
            this.isCanada = true;
            this.isUS = false;
        }
        else if (this.contactInformation.trustedContactPerson.country === COUNTRY_CODE.USA) {
            this.isCanada = false;
            this.isUS = true;
        }

        /**
         * To show soft warning message to update the new address in OLB system
         *
         */
         this.isOlbUser = Utils.getIsOLBUser();
        if(this.isOlbUser){
            this.prefillPostalCode = {
                softWarningMsg: true,
                postalCode: this.contactInformation.postalCode
            }
        }

    }

    updateSignOutResume() {
        this.applicationService.applicationNumber$.subscribe(() => {
            // For branch-assisted ----test for build
            if (!Utils.getIsBranchAssisted()) {
                if (Utils.getIsInSession()) {
                    this.applicationService.setSignOutLink(true);
                    this.showFinishLater = false;
                } else {
                    this.applicationService.setSignOutLink(false);
                    this.showFinishLater = true;
                }
            } else {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            }
        });
    }


    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {

        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.contactInformationForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }

    ngAfterViewInit() {

        //this.analytics.setState('step 5');

        this.analytics.setPageState(this.url);
        this.analytics.triggerView('ContactInformation');

        setTimeout(() => {
            this.contactInformationForm.controls['province'].valueChanges.subscribe(value => {
                this.selectProvince(value);
                this.checkAgeOfMajority();
            });

        }, 1000);

        this.checkFormValidityOnSignOut();

    }

    updateDiffProvince(event) {
        this.selectDiffProvince(event.target.value);
    }


    tcpCountryChange(event) {
        let countryCode = event.target.value;
        this.resetTcpAddressFields();


        if (countryCode === COUNTRY_CODE.CANADA) {
            this.tcpProvinceTranslate = "contactInformation.labels.province";
            this.isCanada = true;
            this.isUS = false;
        }
        else if (countryCode === COUNTRY_CODE.USA) {
            this.tcpProvinceTranslate = "contactInformation.labels.state";
            this.isCanada = false;
            this.isUS = true;
            // to be removed after implementing usa address autocomplete

        }
        else {
            this.isCanada = false;
            this.isUS = false;
        }

        this.loadProvince(countryCode);

    }


    getCountryIsoCode(code: string) {
        let country = this.references.countries.find(country => {
            return country.code === code;
        })
        return country.isoCode
    }

    loadProvince(code: any = COUNTRY_CODE.CANADA) {
        this.setprovince(code);

    }
    setprovince(code) {
        this.references = this.applicationService.appReferences.references;
        this.references.countries.find(function (match) {
            if (code === match.code) {
                this.provinces = [];
                if (code == COUNTRY_CODE.CANADA) {
                    return this.provinces = match.stateProvinces;
                } else if (code = COUNTRY_CODE.USA) {
                    return this.usProvinces = match.stateProvinces;
                }
            }



        }.bind(this));

    }
    getProvinces(countryCode: any = COUNTRY_CODE.CANADA) {
        this.loadProvince(countryCode);
        if (countryCode === COUNTRY_CODE.CANADA) {
            return this.provinces;
        } else if (countryCode === COUNTRY_CODE.USA) {
            return this.usProvinces;
        }
    }


    resetTcpAddressFields() {
        this.contactInformation.trustedContactPerson.province = null;
        this.contactInformation.trustedContactPerson.postalCode = null;
        this.contactInformation.trustedContactPerson.streetNumberName = null;
        this.contactInformation.trustedContactPerson.city = null;
        this.contactInformation.trustedContactPerson.unit = null;
    }

    updateAddressNumberOfYears() {
        if (this.contactInformation.addressNumberOfYears === 'No') {
            // this.contactInformation.previousResidenceLocation = null;
        }
    }
    previousResidenceLocation() {
        if (this.contactInformation.previousResidenceLocation === 'Outside Canada') {
            // this.provincePrevAddress.code = '';
            // this.provincePrevAddress.label = '';
            // this.contactInformation.prevUnit = '';
            // this.contactInformation.prevStreetNumberName = '';
            // this.contactInformation.prevCity = '';
            // this.contactInformation.prevProvince = null;
            // this.contactInformation.prevPostalCode = '';
        }
    }
    updateReceiveMail() {
        this.analytics.triggerView('MailSameAddress' + this.contactInformation.sameMailingAddress);
        if (this.contactInformation.sameMailingAddress === 'No') {
            // if user is providing different address, make different address fields mandatory
            // this.diffAddressRequired = true;
            this.contactInformation.diffAddressRequired = true;
            if (this.contactInformation.diffPostalCode === undefined) {
                this.contactInformation.diffPostalCode = '';
            }
        } else if (this.contactInformation.sameMailingAddress === 'Yes') {
            // if user is not providing different address, make different address fields optional
            // this.diffAddressRequired = false;
            this.contactInformation.diffAddressRequired = false;
        }
    }

    // To check if applicant has age of majority for province
    checkAgeOfMajority() {
        // sometimes during refresh, data doesnt exist, so using &&
        const dob = this.formService.forms.personalInformation.data &&
            this.formService.forms.personalInformation.data.dateOfBirth;

        // If NOT of majority then set error flag.
        if (!this.formService.isAgeOfMajorityForProvince(dob, this.contactInformation.province)) {
            this.contactInformationForm.controls['province'].setErrors({ notAgeOfMajority: true });
        } else {
            this.contactInformationForm.controls['province'].setErrors(null);
        }
    }



    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {

        if (!this.contactInformationForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        if (this.contactInformationForm.valid) {
            this.formService.forms.contactInformation.isCompleted = true;
        }

        this.formService.setFormData('contactInformation', this.contactInformation);
        // To prepare payload
        const personalInformation = this.formService.getFormData('personalInformation');
        // console.log('contact : personalInformation: ', personalInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        // save current page if form is valid
        if (!this.formService.forms.contactInformation.isCompleted) {
            if (Utils.getIsOLBUser() || Utils.getIsJourneyUser()) {
                this.prefillService.contactInfoPrefill();
            } else {
                const emptyContact = new ContactInformation();
                this.formService.getPayload().setContactInformation(emptyContact);
            }
        } else {
            if (this.contactInformationForm.valid) {
                this.formService.getPayload().setContactInformation(this.contactInformation);
            }
        }

        // set beneficiary condition
        this.setBeneficiaryPageCondition();

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {

                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });

    }



    saveOnSignOut() {

        this.resetComponentObjectAttr();
        this.formService.forms.contactInformation.isCompleted = true;
        this.formService.setFormData('contactInformation', this.contactInformation);

        // To prepare payload
        const personalInformation = this.formService.getFormData('personalInformation');
        this.formService.getPayload().setPersonalInformation(personalInformation);

        // save current page if form is valid
        if (Utils.getIsBranchReferral()) {
            if (this.contactInformationForm.valid) {
                this.formService.getPayload().setContactInformation(this.contactInformation);
            }
        } else {
            if (!this.formService.forms.contactInformation.isCompleted) {
                const emptyContact = new ContactInformation();
                this.formService.getPayload().setContactInformation(emptyContact);
            } else {
                if (this.contactInformationForm.valid) {
                    this.formService.getPayload().setContactInformation(this.contactInformation);
                }
            }
        }

        // set beneficiary page condition
        this.setBeneficiaryPageCondition();

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    onSubmit() {

        // For accessibility purposes, set focus on the invalid element.
        if (!this.contactInformationForm.valid) {
            this.setFocus();
        }

        this.isFormSubmit = true; // for validation

        if (!this.contactInformation.trustedContactPerson.isTrustedPerson) {
            this.clearTrustedPersonObject();
        }


        if (this.contactInformationForm.valid) {
            // MWB-11123 - The Province  for  mailing address displayed as '' is fixed
            if (this.contactInformation.diffProvince) {
                this.selectDiffProvince(this.contactInformation.diffProvince);
            }
            this.resetComponentObjectAttr();
            this.formService.forms.contactInformation.isCompleted = true;
            this.formService.setFormData('contactInformation', this.contactInformation);

            // To prepare payload
            const personalInformation = this.formService.getFormData('personalInformation');
            // console.log('contact : personalInformation: ', personalInformation);
            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(this.contactInformation);

            // set beneficiary page condition
            this.setBeneficiaryPageCondition();
            this.applicationService.autoSave(this.contactInformationForm, APP_ROUTES.employment_information);

            if (Utils.getIsInSession() === false) {

                this.applicationService.saveAndcontinue(this.contactInformation, APP_ROUTES.employment_information);

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {
                            this.applicationService.saveAndcontinue(this.contactInformation, APP_ROUTES.employment_information);
                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }

        } else {
            // console.log('form is invalid');
        }

    }

    setBeneficiaryPageCondition() {

        // set beneficiary condition (Quebec = 110)
        this.formService.getPayload().parties[0].personal.residence.primaryAddress.province = this.contactInformation.province;
        if (((this.formService.getPayload().accounts[0].type === '100562') ||
            (this.formService.getPayload().accounts[0].type === '100391') ||
            (this.formService.getPayload().accounts[0].type === '100555')) &&
            this.formService.getPayload().parties[0].personal.residence.primaryAddress.province !== '110' &&  (Utils.getIsBranchAssisted() || Utils.hasBeneficiaryDetails())) {
                if(!Utils.isBranchSDMultiAccountFlow()){
                    Utils.setBeneficiaryPageCondition(true);
                    this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
                }
        } else {
            Utils.setBeneficiaryPageCondition(false);
            this.applicationService.setBeneficiaryNavigationDisabled(true); // Disable Navigation -> Event Emitted

           // this.formService.getPayload().accounts[0].hasBeneficiaries =false;

        }

    }


    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.

        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.contactInformationForm);
           }, 250);

    }

    selectProvince(provinceCode, countryCode: any = COUNTRY_CODE.CANADA) {


        const provinceData = Utils.getProvinceLabel(this.getProvinces(countryCode), provinceCode);

        this.contactInformation.provinceLabel = provinceData.label;
    }

    selectDiffProvince(provinceCode) {
        /*const provinceData = this.provinces.find(function (obj) {
            return obj.code === provinceCode;
        });*/

        const provinceData = Utils.getProvinceLabel(this.provinces, provinceCode);

        if (provinceData !== undefined) {
            this.contactInformation.diffProvinceLabel = provinceData.label;
        }
    }
    resetComponentObjectAttr() {
        if (this.contactInformation.province) {
            this.selectProvince(this.contactInformation.province); // Add Province label if province is selected
        }

        if (this.contactInformation.sameMailingAddress === 'Yes') {
            // If user selects at primary residence
            this.contactInformation.diffStreetNumberName = null;
            this.contactInformation.diffProvince = null;
            this.contactInformation.diffPostalCode = null;
            this.contactInformation.diffProvince = null;
            this.contactInformation.diffUnit = null;
            this.contactInformation.diffCity = null;
        }
    }

    ngOnDestroy() {

        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        if (this.contactInformation.diffProvince) {
            this.selectDiffProvince(this.contactInformation.diffProvince);
        }
        this.setBeneficiaryPageCondition();
        this.formService.setFormData('contactInformation', this.contactInformation);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleContactInformation(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.CONTACT_INFO,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.contact_information,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log('contact', this.winRef.nativeWindow.dataLayer);

    }
    // updateFilteredCountriesList(index) {
    //     console.log("-index-", index)
    //     this.formService.setActiveForm(this.contactInformationForm);
    //   }

    data(formName, fieldName) {
        if (this.formService.forms[formName].data !== null && this.formService.forms[formName].data[fieldName] !== null) {
            return this.formService.forms[formName].data[fieldName];

        }
        return '';
    }
    setTcp() {

        this.contactInformation.trustedContactPerson.isTrustedPerson = this.data('contactInformation', 'trustedContactPerson').isTrustedPerson;
        console.log(this.contactInformation);

        if (this.contactInformation.trustedContactPerson.isTrustedPerson) {
            this.contactInformation.trustedContactPerson.firstname = this.data('contactInformation', 'trustedContactPerson').firstname || '';
            this.contactInformation.trustedContactPerson.initial = this.data('contactInformation', 'trustedContactPerson').initial || '';
            this.contactInformation.trustedContactPerson.lastname = this.data('contactInformation', 'trustedContactPerson').lastname || '';
            this.contactInformation.trustedContactPerson.country = this.data('contactInformation', 'trustedContactPerson').country || '';
            this.contactInformation.trustedContactPerson.province = this.data('contactInformation', 'trustedContactPerson').province || '';
            this.contactInformation.trustedContactPerson.postalCode = this.data('contactInformation', 'trustedContactPerson').postalCode || '';
            this.contactInformation.trustedContactPerson.streetNumberName = this.data('contactInformation', 'trustedContactPerson').streetNumberName || '';
            this.contactInformation.trustedContactPerson.city = this.data('contactInformation', 'trustedContactPerson').city || '';
            this.contactInformation.trustedContactPerson.unit = this.data('contactInformation', 'trustedContactPerson').unit || '';
            this.contactInformation.trustedContactPerson.phoneNumberHome = this.data('contactInformation', 'trustedContactPerson').phoneNumberHome || '';
            this.contactInformation.trustedContactPerson.phoneNumberOther = this.data('contactInformation', 'trustedContactPerson').phoneNumberOther || '';
            this.contactInformation.trustedContactPerson.emailAddress = this.data('contactInformation', 'trustedContactPerson').emailAddress || '';
            this.contactInformation.trustedContactPerson.tcpRelationShip = this.data('contactInformation', 'trustedContactPerson').tcpRelationShip;
            this.contactInformation.trustedContactPerson.isInternationalNumber = this.data('contactInformation', 'trustedContactPerson').isInternationalNumber;

        }


    }

    clearTrustedPersonObject() {
        this.contactInformation.trustedContactPerson.firstname = null;
        this.contactInformation.trustedContactPerson.initial = null;
        this.contactInformation.trustedContactPerson.lastname = null;
        this.contactInformation.trustedContactPerson.country = null;
        this.contactInformation.trustedContactPerson.province = null;
        this.contactInformation.trustedContactPerson.postalCode = null;
        this.contactInformation.trustedContactPerson.streetNumberName = null;
        this.contactInformation.trustedContactPerson.city = null;
        this.contactInformation.trustedContactPerson.unit = null;
        this.contactInformation.trustedContactPerson.phoneNumberHome = null;
        this.contactInformation.trustedContactPerson.phoneNumberOther = null;
        this.contactInformation.trustedContactPerson.emailAddress = null;
        this.contactInformation.trustedContactPerson.tcpRelationShip = null;
        this.contactInformation.trustedContactPerson.isInternationalNumber = null;
    }
    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }

    isTcpEmailCollected() {
        return this.isValueNull(this.contactInformation.trustedContactPerson.emailAddress) ? false : true;
    }

    isTcpPhoneCollected() {
        const phoneHome = !this.isValueNull(this.contactInformation.trustedContactPerson.phoneNumberHome);
        const phoneOther = !this.isValueNull(this.contactInformation.trustedContactPerson.phoneNumberOther);
        if (phoneHome || phoneOther) {
            return true;
        }
        return false;
    }

    isTcpAddressCollected() {
        const tcpCountry = !this.isValueNull(this.contactInformation.trustedContactPerson.country);
        const tcpProvince = !this.isValueNull(this.contactInformation.trustedContactPerson.province);
        const tcpPostalCode = !this.isValueNull(this.contactInformation.trustedContactPerson.postalCode);
        const tcpStreetName = !this.isValueNull(this.contactInformation.trustedContactPerson.streetNumberName);
        const tcpCity = !this.isValueNull(this.contactInformation.trustedContactPerson.city);
        if (tcpCountry && tcpProvince && tcpPostalCode && tcpStreetName && tcpCity) {
            return true;
        }
        return false;
    }
    isTcpHomePhoneCollected() {
        return this.isValueNull(this.contactInformation.trustedContactPerson.phoneNumberHome) ? false : true;

    }

    isTcpOtherPhoneCollected() {
        return this.isValueNull(this.contactInformation.trustedContactPerson.phoneNumberOther) ? false : true;

    }
//Below code is for TCP address autopopulate
    onStreetNameChange(event) {

        if (this.contactInformation.trustedContactPerson.country !== COUNTRY_CODE.CANADA &&
            this.contactInformation.trustedContactPerson.country !== COUNTRY_CODE.USA) {
            if (this.canPostControl) {
                 this.canPostControl.destroy();
                this.canPostControl = null;
            }
            return;
        }

        const { pca } = this.winRef.nativeWindow;
        const fields = [{
            element: event.target.id,
            field: 'Line1',
            mode: pca.fieldMode.SEARCH
        }];
        const MaxSuggestions = 50;
        const MaxResults = 300;
        const minItems = 1;
        const maxItems = 10;
        const options = {
            key: this.applicationService.getCanadaPostKey(),
            maxItems: maxItems,
            minItems: minItems,
            search: {
                maxSuggestions: MaxSuggestions,
                maxResults: MaxResults
            },
            bar: {
                showCountry: false,
                showLogo: false,
                logoLink: false
            },
            languagePreference: this.isCurrentLangEnglish ? 'en-US' : 'fr-CA'
        };

        // don't create new pca.Address it will create multiple popups
        if (!this.canPostControl) {
            this.canPostControl = new pca.Address(fields, options);
        }

        this.canPostControl.setCountry(
            this.contactInformation.trustedContactPerson.country === COUNTRY_CODE.CANADA ? 'CA' : 'US'
        );

        this.canPostControl.listen('populate', (address) => {

            if (this.contactInformation.trustedContactPerson.country !== COUNTRY_CODE.CANADA &&
                this.contactInformation.trustedContactPerson.country !== COUNTRY_CODE.USA) {
                return;
            }

            let provinceData;
            if (this.contactInformation.trustedContactPerson.country === COUNTRY_CODE.USA) {
                provinceData = this.usProvinces.find(obj => obj.isoCode === address.ProvinceCode);
            } else {
                provinceData = this.provinces.find(obj => obj.isoCode === address.ProvinceCode);
            }

            this.contactInformation.trustedContactPerson.streetNumberName = address.Line1;
            this.contactInformation.trustedContactPerson.city = address.City;
            this.contactInformation.trustedContactPerson.province = provinceData.code;
            this.contactInformation.trustedContactPerson.postalCode = address.PostalCode;

        });
    }

}

