import { Injectable } from '@angular/core';
import { Observable, Subject, EMPTY, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';


import * as $ from 'jquery';
import { LoaderService } from '../../shared/service/loader.service';

import { Utils } from '../../shared/utils/utils.class';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('uploadDoc') === -1 && Utils.getEnableLoader()=== true) {
      this.loaderService.show();
    }
    // appends new header to each request


    const requestWithTrackingID = request.clone({ headers: request.headers.set('X-BMO-REQUEST-ID', '' + Utils.getUUID()) });



    return next
      .handle(requestWithTrackingID)
      .pipe(tap(event => {
      }, error => {
      }))
      .pipe(finalize(() => {
        if (request.url.indexOf('uploadDoc') === -1 && Utils.getEnableLoader()=== true) {
          this.loaderService.hide();
        }
        Utils.setEnableLoader(true);
      }));
  }
}

