import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../service/application.service';
import { AccountTypeService } from '../service/account-type.service';
import { Utils } from '../utils/utils.class';

@Injectable()
export class AccountsResolver  {


    constructor(private applicationService: ApplicationService,
        private route: ActivatedRoute,
        private accountTypeService: AccountTypeService) { }

    resolve(route: ActivatedRouteSnapshot) {
        // console.log("AccountsResolver");
        return this.accountTypeService.getAccounts();
    }
}
