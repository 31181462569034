import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, LOCATION_INDEX, ANALYTICS, ACCOUNT_NAME_EN, ACCOUNT_TYPE, PRODUCT, SUCCESS_RESPONSE } from './../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { isNil, filter, includes, some } from 'lodash';
import { Title } from '@angular/platform-browser';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { PersonalInformation } from '../../sections/personal-information/personal-information.component';


// Model class to store page information
export class AccountDetail {
    public creditCheck = '';
    public needShortSelling = '';
    public needOptionsTrading = '';
    public yearsOfExperience = '';
    public tradingKnowledgeLevel = '';
    public typesOfOptionsTradedBefore = '';
    public typesOfOptionsTradedNow = '';
    public selectedAccount = '';
    public promocode = '';
    public tncChecked = '';
    public tncDocName = '';

}

@Component({
    selector: 'app-account-detail',
    templateUrl: './account-detail.component.html'
})
export class AccountDetailComponent implements OnInit, AfterViewInit {

    @ViewChild('accountDetailsForm', {static: true}) accountDetailsForm: NgForm;
    public isCash: Boolean = false;
    public isTfsa: Boolean = false;
    public isRRSP: Boolean = false;
    public isSRRSP: Boolean = false;
    public isMargin: Boolean = false;
    public isFormSubmit: Boolean = false;
    public isCurrentLangEnglish: Boolean = false;
    public isCurrentLangFrench: Boolean = false;
    public disableContinue: Boolean = false;
    public accountDetail;
    public listOfOptionsTradingKnowledge;
    public optionsTypeExperiences;
    public optionTypes;
    public isCodeApplied: Boolean = false;
    public promoApplied: Boolean = false;
    public lang: string;
    public isReferenceCallFailed: Boolean = false;
    public promoServiceFail: Boolean = false;
    public disableNeverTraded: Boolean = null;
    public isSubProductAD: Boolean = null;
    public APP_ROUTES = APP_ROUTES;
    public url;


    constructor(public applicationService: ApplicationService,
        private winRef: WindowRefService,
        private titleService: Title,
        public formService: FormService,
        private route: ActivatedRoute,
        private analytics: AnalyticsService) {

        this.accountDetail = new AccountDetail();
        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.url = '/' + APP_ROUTES.account_detail;
        // this.formService.setActiveComponent('personalInformation', this);
    }

    ngOnInit() {

        if (Utils.getIsInSession()) {
            this.applicationService.setSignOutLink(true);
        } else {
            this.applicationService.setSignOutLink(false);
        }

        this.isSubProductAD = Utils.isSubProductAD();

        this.titleService.setTitle(Utils.getPageTitleAccountDetails());
        // To understand which account is selected by user
        this.isCash = Utils.isCash();
        this.isTfsa = Utils.isTFSA();
        this.isRRSP = Utils.isRRSP();
        this.isSRRSP = Utils.isSpousalRRSP();
        this.isMargin = Utils.isMargin();

        if (this.isCash) {
            Utils.accountName = PRODUCT.CASH.name;
            Utils.accountNumber = PRODUCT.CASH.number;
        } else if (this.isTfsa) {
            Utils.accountName = PRODUCT.TFSA.name;
            Utils.accountNumber = PRODUCT.TFSA.number;
        } else if (this.isRRSP) {
            Utils.accountName = PRODUCT.RRSP.name;
            Utils.accountNumber = PRODUCT.RRSP.number;
        } else if (this.isSRRSP) {
            Utils.accountName = PRODUCT.SRRSP.name;
            Utils.accountNumber = PRODUCT.SRRSP.number;
        }

        // Fetch references from backend
        this.applicationService.fetchReferences(this.route);

        this.disableContinue = ((this.isTfsa || this.isRRSP || this.isSRRSP) && !this.isSubProductAD) ? true : false;

        // if appReferences are not null and not undefined then get values from RDM
        if (!isNil(this.applicationService.appReferences)) {

            this.listOfOptionsTradingKnowledge = this.applicationService.appReferences.references.optionsKnowledge;
            this.optionsTypeExperiences = this.applicationService.appReferences.references.optionsTypeExperiences;
            this.optionTypes = this.applicationService.appReferences.references.optionTypes;

            for (let i = 0; i < this.optionsTypeExperiences.length; i++) {
                this.optionsTypeExperiences[i].checked = false; // Add "checked": false to all objects in array
            }

            for (let i = 0; i < this.optionTypes.length; i++) {

                if (this.isCash || this.isRRSP || this.isSRRSP || this.isTfsa) {

                    // if account is of above type... remove spread from optiontypes
                    // spread should be displayed in case of margin account
                    this.optionTypes = this.optionTypes.filter(function (obj) {
                        return obj.code !== '3'; // for spread code is 3
                    });
                }

                this.optionTypes[i].checked = false; // Add "checked": false to all objects in array
            }

        }

        this.route.queryParams.subscribe(params => {
            if (params.lang) {
                this.lang = params.lang;
            } else {
                this.lang = 'en';
            }
        });



        setTimeout(() => {
            this.setGTM();
        }, 200);
    }

    ngAfterViewInit() {
        //this.analytics.setState('step 2');

        this.analytics.setPageState(this.url);
        // this.analytics.setvPageTitle(Utils.getPageTitleAccountDetails());
        // this.analytics.setOriginalLocation(Utils.getPageTitleAccountDetails());
        // this.analytics.setvURL(Utils.getvURL());
    }

    getOptionsOfTradedBefore() {
        this.accountDetail.typesOfOptionsTradedBefore = this.optionsTypeExperiences;
        // returns trade options which are checked.
        const checkedTypeExp = filter(this.optionsTypeExperiences, (exp) => {
            return exp.checked;
        });
        // if checked items include any of the other 3 options,then the never traded option(code 3) has to be disbaled.
        this.disableNeverTraded = !some(checkedTypeExp, { code: '3' }) && checkedTypeExp.length > 0;

        // when no item is checked, disable option is set to null
        if (checkedTypeExp.length === 0) {
            this.disableNeverTraded = null;
        }
    }

    getOptionTypes() {
        // this.accountDetailsForm.value.typesOfOptionsTradedNow = this.optionTypes;

        this.accountDetail.typesOfOptionsTradedNow = this.optionTypes;

    }
    onNeedOptionsTradingSelected() {
        if (this.accountDetail.needOptionsTrading === 'No' && this.isReferenceCallFailed === true) {
            this.isReferenceCallFailed = false;
        }
        this.enableDisableContinue();
    }
    enableDisableContinue() {

        if (this.isRRSP || this.isSRRSP || this.isTfsa) {
            if (this.accountDetail.needOptionsTrading !== '') {
                if ((this.promoApplied && this.accountDetail.tncChecked) || !this.isCodeApplied) {
                    this.disableContinue = false;
                } else {
                    this.disableContinue = true;
                }
            } else {
                this.disableContinue = this.isSubProductAD ? false : true;
            }
        } else if (this.isCash) {
            this.disableContinue = (this.promoApplied && this.accountDetail.tncChecked) || this.accountDetail.promocode === '' ?
                false : true;
        }
        // else if (this.isTfsa) {
        //     this.disableContinue = (this.accountDetail.needOptionsTrading !== '') || this.accountDetail.promocode === '' ? false : true;
        // }
    }
    onTNCChecked(ischecked) {
        this.accountDetail.tncChecked = ischecked;
        this.enableDisableContinue();
    }
    onPromoCodeChange(value) {
        if (value.length < 1) {
            this.isCodeApplied = false;
            this.enableDisableContinue();
        }
    }
    promoCodeApplied() {
        if ((this.isRRSP || this.isSRRSP || this.isCash || this.isTfsa) && this.accountDetail.promocode !== '') {
            this.applicationService.applyPromoCode(this.lang, this.accountDetail.promocode)
                .then((res: any) => {
                    this.isCodeApplied = true;
                    if (res.isReferenceCallFailed) {
                        this.isReferenceCallFailed = true;
                        this.promoServiceFail = true;
                        this.isCodeApplied = false;
                    } else if (res.validPromo) {
                        this.accountDetail.tncChecked = false;
                        this.promoApplied = true;
                        this.accountDetail.tncDocName = res.termConditionlink;
                        this.enableDisableContinue();
                    } else {
                        this.promoApplied = false;
                        this.enableDisableContinue();
                        this.accountDetail.tncChecked = false;
                    }
                })
                .catch(err => {
                    this.isReferenceCallFailed = true;
                    this.promoServiceFail = true;
                });
        }
    }

    onSubmit() {
        this.isReferenceCallFailed = false;
        this.isFormSubmit = true;
        if (this.accountDetailsForm.valid) {


            if ((this.isRRSP || this.isSRRSP || this.isCash || this.isTfsa) && this.accountDetail.promocode !== '') {
                this.applicationService.applyPromoCode(this.lang, this.accountDetail.promocode)
                    .then((res: any) => {
                        this.isCodeApplied = true;
                        if (res.isReferenceCallFailed) {
                            this.isReferenceCallFailed = true;
                            this.promoServiceFail = true;
                            this.isCodeApplied = false;
                            if ((this.isRRSP || this.isSRRSP || this.isMargin || this.isTfsa) && !this.promoServiceFail) {
                                this.formService.setFormData('accountDetail', this.accountDetail);
                                this.formService.getPayload().setAccountDetails(this.accountDetail);

                                if (Utils.getIsBranchReferral()) { // Branch-referral
                                    this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.personal_information);

                                } else {
                                    this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.set_expectation);
                                }
                            }
                        } else if (res.validPromo) {
                            this.promoApplied = true;
                            this.accountDetail.tncDocName = res.termConditionlink;
                            this.enableDisableContinue();
                            // navigates to set expectations screen
                            if (this.accountDetail.tncChecked) {
                                this.formService.setFormData('accountDetail', this.accountDetail);
                                this.formService.getPayload().setAccountDetails(this.accountDetail);

                                if (Utils.getIsBranchReferral()) { // Branch-referral
                                    this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.branch_save_progress);


                                } else {
                                    this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.set_expectation);
                                }
                            }
                        } else {
                            this.promoApplied = false;
                            this.enableDisableContinue();
                            this.accountDetail.tncChecked = false;
                        }
                    })
                    .catch(err => {
                        this.isReferenceCallFailed = true;
                        this.promoServiceFail = true;
                    });
            } else {
                //  navigates to set expectations screen
                this.formService.setFormData('accountDetail', this.accountDetail);
                this.formService.getPayload().setAccountDetails(this.accountDetail);
                if (Utils.getIsBranchReferral()) { // Branch-referral
                    if (Utils.getPageStatus() === null) { /**This condition was added in case user logout on invalid personal info page. */
                        this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.personal_information);
                    } else {
                        this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.branch_save_progress);
                    }

                } else {
                    this.applicationService.saveAndcontinue(this.accountDetailsForm, APP_ROUTES.set_expectation);
                }
            }
        } else {
            this.isReferenceCallFailed = true;
        }
    }

    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOVPageView,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleAccountDetails(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.ACCOUNT_DETAILS,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.account_detail,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': ''
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

}

