import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { DocumentTrackerService } from '../../shared/service/document-tracker.service';
import { LoaderService } from '../../shared/service/loader.service';
import { ApplicationService } from '../../shared/service/application.service';
import { Utils } from '../../shared/utils/utils.class';
import { APP_ROUTES, DOCUMENT_TYPE , DOWNGRADE_DECISIONS} from '../../config/app-config.constants';
import { Jquery } from "../../shared/utils/jquery.class";

@Component({
  selector: 'app-application-approval-downgrade',
  templateUrl: './application-approval-downgrade.component.html'
})
export class ApplicationApprovalDowngradeComponent implements OnInit {
  public isSubProductAD: boolean;
  public document_package: any;
  public isRetry = false;
  public firstName: string;
  public applicationNumber : string;
  public url: any;
  public accountTypesCount : number;

  public isMultiAccountFlow: boolean = false;
  public isBranchSDMultiAccountFlow: boolean;
  public selectedMultiAccounts = [];
  public selectedDowngradeDecision = '';
  public downgradeDecisions = DOWNGRADE_DECISIONS;
  selectedRegisteredAccounts: any[];
  constructor(public applicationService: ApplicationService,
    private http: HttpClient,
    private documentTrackerService: DocumentTrackerService,
    private analytics: AnalyticsService,
    private loaderService: LoaderService, private configService: ConfigResolverService<any>) {
    this.url = '/' + APP_ROUTES.application_approval_downgrade;
    }

  ngOnInit() {
    this.isMultiAccountFlow = Utils.isMultiAccountFlow();
    this.isBranchSDMultiAccountFlow = Utils.isBranchSDMultiAccountFlow();
    this.selectedMultiAccounts = Utils.selectedMultiAccounts;
    this.selectedRegisteredAccounts = this.selectedMultiAccounts?.filter(account => account.id !=  'Margin');
    this.selectedDowngradeDecision = this.downgradeDecisions.accept_downgrade;
    if (Utils.getIsInSession()) {
      this.applicationService.setSignOutLink(true);
    } else {
      this.applicationService.setSignOutLink(false);
    }
    this.isSubProductAD = Utils.isSubProductAD();
    this.accountTypesCount = Utils.selectedMultiAccounts?.length
    this.document_package = Utils.getDocumentsPackage();
    if(!this.document_package){
      this.document_package = Utils.getApplicationObject();
    }
    this.firstName = this.document_package.parties[0].personal.identity.legalName.firstName;
    this.applicationNumber = this.document_package.applicationNumber;
    this.getCancelMarginDownGradeModalDecision();
  }

  ngAfterViewInit() {
    // this.analytics.setState('step 16a');
    this.analytics.setPageState(this.url);
    this.analytics.setDowngradeStatus("declined");
}

  continueApplication(appStatus: any) {
    const config = this.configService.getConfig();
    const applicationDwongradeURL = config.CHANNELS_API.CONTEXT_ROOT + config.CHANNELS_API[appStatus];
    let header = config.CHANNELS_API.DOWNGRADE_DOCUMENT_HEADER;

    if (Utils.getIsBranchReferral() === true) {
      header['branch_assisted'] = 'true';
    }
    header['workflow_id'] = this.document_package.applicationId;
    this.http.post(applicationDwongradeURL, {}, { headers: header, responseType: 'text', observe: 'response' }).subscribe((response) => {
      if (appStatus == this.downgradeDecisions.cancel_downgrade) {
        this.analytics.setCancelDowngrade();
        this.applicationService.onSignOut();
        Utils.setIsInSession(false);
      } else {
        this.analytics.setAcceptDowngrade();
        if (Utils.getIsBranchReferral() === true) {
          this.documentTrackerService.generateSpecificDocuments(this.document_package.applicationId, DOCUMENT_TYPE.CUSTOMER_PACKAGE).subscribe(
            (res: any) => {
                const response = JSON.parse(res.body)[0];
                    this.documentTrackerService.documentPackage = response;
                    this.applicationService.saveAndcontinue(null, APP_ROUTES.branch_esignature);
            },
            error => {
            });
        } else{
          this.esignCall(JSON.parse(response.body)[0]);
        }
      }
    },
      (error) => {
        this.analytics.setapiCallDetails({
          apiCallMade: 'true', apiCallType: appStatus,
          apiCallResponseCode: error.error?.errorInfo ? error.status?.toString() + "-" + error.error?.errorInfo.errorCode.toString() : error.status?.toString()

        });
      })
  }

  esignCall(response) {
    this.loaderService.show();
    this.documentTrackerService.getSigingUrl(response.packageId,response.signerId).subscribe(
      (res: any) => {
        if (res.url === null && !this.isRetry) {
          this.isRetry = true;
          this.esignCall(response);
        } else {
          this.analytics.setapiCallDetails({apiCallMade: 'false', apiCallType: '', apiCallResponseCode: '' });
          window.location.href = res.url;
        }
      },
      err => {
        this.loaderService.setDisableHideAction(false);
        this.loaderService.hide();
      });
  }
  onCancelDowngrade() {
    $('.bmo-margin-downgrade, .modal-overlay').show();
    Jquery.modalFocusTrap('bmo-margin-downgrade');
  }

  getCancelMarginDownGradeModalDecision() {
    this.applicationService.marginCancelDowngradeModal$.subscribe(response => {
     if(response === DOWNGRADE_DECISIONS.cancel_downgrade) {
         this.continueApplication(DOWNGRADE_DECISIONS.cancel_downgrade);
     } else {
      this.selectedDowngradeDecision = DOWNGRADE_DECISIONS.accept_downgrade;
      document.getElementById('cancelApplicationAccount').focus();
     }
   });
  }
}
