/*
@created by: Dilraj Singh
*/
import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { Utils } from '../utils/utils.class';
import { isMoment } from 'moment';
import { FormService } from '../service/form.service';


const dateFormatRegex = /^\d{4}[\/-]\d{1,2}[\/-]\d{1,2}$/;
const dateFormat = 'YYYY-MM-DD';

// export const dateOfBirthValidator = (control: AbstractControl, accountTypeService: AccountTypeService) => {
export const dateOfBirthValidator = (control: AbstractControl, formService: FormService, isAgeValidation: boolean): { [key: string]: boolean } => {
  // Added accountTypeService for Account type validation for RRSP

  if (isValuePresent(Validators.required(control))) {
    return { invalidDateOfBirth: true };
  }

  // dateToCheck is the string formated date. I must have no letters and be in the format of YYYY-MM-DD to continue
  const dateToCheck: string = control.value;
  if (!isDate(dateToCheck)) {
    return { invalidDateOfBirth: true };
  }

  const dateValue = moment(dateToCheck, dateFormat);

  // check if the date is an actual date. E.g. February 31st is not.
  if (dateValue.format(dateFormat) === 'Invalid date') {
    return { invalidDateOfBirth: true };
  }

  /**This was added to clear out the field in investor profile screen. */
  if (Utils.ageEqualSeventyOrGreater(dateValue) === false) {
    if (formService.forms.investorProfile.data) {
      formService.forms.investorProfile.data.riskLevelAcceptedForAgeSeventy = null;
    }
    formService.getPayload().accounts[0].investmentObjectives.riskLevelAcceptedForAgeSeventy = null;
  }

  if (isUnder18(dateToCheck) && isAgeValidation === true) {
    return { invalidAgeUnderEighteen: true };
  }


  if ((Utils.isRRSP() || Utils.isSpousalRRSP()) && isUnder72(dateToCheck) && isAgeValidation === true ) {
    // Error is set if Account type  is RRSP
    return { invalidNotUnder72: true };
  }
  return null;
};

function isValuePresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

function isDate(obj: any): boolean {
  return dateFormatRegex.test(obj.toString());
}

function isUnder18(dateValue): boolean {
  return isWithinNumberOfYears(dateValue, 18);
}

function isUnder72(dateValue): boolean {

  /*let dateOfBirthPlus72 = dateValue.add(72, 'years');
  let currentYear = moment();

  return dateOfBirthPlus72.isSameOrBefore(currentYear, 'years');*/

  //Moment library is not returning correct values at certain times,
  //which is why it was converted to date for comparision purposes.

  let currentYearMinus72 = moment().subtract(72, 'years').toDate();
  dateValue = new Date(dateValue);

  return (dateValue <= currentYearMinus72);
}

function isWithinNumberOfYears(dateValue, numberOfYears: number) {
  let yearsPrior = moment().subtract(numberOfYears, 'years').toDate();
  dateValue = new Date(dateValue);

  return (dateValue > yearsPrior);
  //return yearsPrior.isAfter(dateValue, 'day');
}
