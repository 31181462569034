import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, forwardRef, OnChanges, EventEmitter, Output, Renderer2 } from '@angular/core';
import moment from 'moment';
import { dateOfBirthValidator } from './../../validators/date.validator';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '../../../shared/service/application.service';
import { FormService } from '../../../shared/service/form.service';

const touched = () => { };
const dateFormat = 'YYYY-MM-DD';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatePickerComponent), multi: true }
  ]
})
/**
 * Created by Dilraj 2018
 * Date Picker creates a calendar that will display the chosen date in 'MM | DD | YYYY' format as set in the date pipe.
 *
 * --> When opened by click or tab event a selection of years will first appear along with the ability to change the decade,
 *     upon selection it will display a selection of months and finally a selection of days. User has the ability to close any
 *     of the views and return back to that stage of the date selection. User can also move back through the calendar.
 * --> Hitting escape will toggle the calendar.
 * --> input [showWeekdays] is set to true by default. Set to "false" to not display weekdays.
 */

export class DatePickerComponent implements OnInit, ControlValueAccessor, OnChanges {
  private date = moment().year(1980);
  private dateToday = moment();

  public placeholder: string;
  public daysArr;
  public yearArr;
  public monthsArr;

  public decade;

  public selectedYear;
  public selectedMonth;
  public selectedDay;

  public showYearCalendar = true;
  public showMonthsCalendar = false;
  public showDaysCalendar = false;

  public showCalendar = false;

  private isPopupActive = false;


  private previousMonthDays = [];
  private nextMonthDays = [];

  private lang: string;

  private _onTouchedCallback: (_: any) => void = touched;
  private _onChangeCallback: (_: any) => void = touched;

  propagateChange: any = () => { };
  validateFn: any = () => { };

  @ViewChild('datePickerPopup', {static: true}) _datePickerPopup: ElementRef;

  @Input('dateValue') _dateValue;
  @Input() disabled;
  @Input() showWeekdays: boolean = true;
  @Input() readonly: boolean = false;
  @Input() isAgeValidation: boolean = true;
  @Output()  public underAgeValidation: EventEmitter<any> = new EventEmitter<Boolean>();
  constructor(private renderer: Renderer2, private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private formService: FormService) {

    this.placeholder = 'MM  |  DD  |  YYYY';
    if (!this.applicationService.isCurrentLangEnglish()) {
      this.placeholder = 'mm  |  jj |  aaaa';
    }
  }

  ngOnInit() {
    // set the language
    this.route.queryParams.subscribe(params => {
      if (params.lang) {
        this.lang = params.lang;
      } else {
        this.lang = 'en';
      }
    });
    moment.locale(this.lang);

    // initiate the calendar
    this.yearArr = this.createYearCalendar(this.date);
    this.monthsArr = moment.monthsShort();
  }

  OnDestroy() {
    // disable the modal backdrop
    // disable the modal backdrop
this.renderer.removeClass(document.body, 'hidden');
    this.renderer.removeStyle(document.querySelector('.modal-overlay'), 'display');
  }

  get dateValue() {
    return this.dateToString;
  }

  set dateValue(val) {
    this._dateValue = val;

    // if we come back to this page and the val exists set it. Assumes all three parameters have str numbers
    if (val && !this.showCalendar) {
      this.selectedYear = +val.split('-')[0];
      this.selectedMonth = +val.split('-')[1];
      this.selectedDay = moment().year(this.selectedYear).month(this.selectedMonth - 1).date(Number(val.split('-')[2]));
      return;
    }

    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
    this.propagateChange(this.dateValue);
  }

  writeValue(value) {
    if (value) {
      this.dateValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  onTouched(event: Event) {
    this._onTouchedCallback(null);
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    this.underAgeValidation.emit(dateOfBirthValidator(c, this.formService, this.isAgeValidation));
    return dateOfBirthValidator(c, this.formService, this.isAgeValidation);
  }


  @HostListener('keyup', ['$event'])
  onkeyup(event: KeyboardEvent) {
    // tab in from another field opens up the calendar
    if (event.keyCode === 9 && !this.showCalendar) {
      this.toggleCalendar();
      return;
    }
    if ((event.keyCode === 8 || event.keyCode === 46) && !this.readonly) {
      this.dateValue = null;
      this.selectedDay = null;
      this.selectedMonth = null;
      this.selectedYear = null;
      event.preventDefault();
      return;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onEscapeKey(event: KeyboardEvent) {
    if (this.showCalendar && (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27)) {
      this.toggleCalendar();
    }
  }

  goBack() {
    if (this.showMonthsCalendar) {
      this.showDaysCalendar = false;
      this.showMonthsCalendar = false;
      this.showYearCalendar = true;
    }
    if (this.showDaysCalendar) {
      this.showDaysCalendar = false;
      this.showMonthsCalendar = true;
      this.showYearCalendar = false;
    }
  }

  chosenYear(year) {
    if (this.selectedYear !== year) {
      this.selectedMonth = null;
      this.selectedDay = null;
    }
    this.selectedYear = year;
    this.showYearCalendar = false;
    this.showMonthsCalendar = true;

    this.dateValue = this.dateToString;
  }

  chosenMonth(month) {
    if (this.selectedMonth !== month + 1) {
      this.selectedDay = null;
    }

    this.selectedMonth = month + 1;

    this.date = moment(this.selectedYear + '-' + this.selectedMonth, dateFormat);
    this.daysArr = this.createDayCalendar(moment(this.selectedYear + '-' + this.selectedMonth + '-1', dateFormat));

    this.showYearCalendar = false;
    this.showMonthsCalendar = false;
    this.showDaysCalendar = true;

    this.dateValue = this.dateToString;
  }

  chosenDay(day) {
    this.selectedDay = day;
    this.dateValue = this.dateToString;
    this.toggleCalendar(true);
  }

  createYearCalendar(date) {
    const yearArr = [];
    this.decade = date.year() - (date.year() % 10);
    for (let n = this.decade; n < this.decade + 10; n++) {
      yearArr.push(n);
    }
    return yearArr;
  }

  previousDecade() {
    this.decade = this.decade - 10;
    const date = moment(this.decade + '-01-01', dateFormat);
    this.yearArr = this.createYearCalendar(date);
  }

  nextDecade() {
    this.decade = this.decade + 10;
    const date = moment(this.decade + '-01-01', dateFormat);
    this.yearArr = this.createYearCalendar(date);
  }

  createDayCalendar(month) {
    const currentMonthFirstDay = moment(month).startOf('M').format(dateFormat);
    const currentMonthLastDay = moment(month).endOf('M').format(dateFormat);


    // MWB-12740 : Bug fix: weekday is Locale specific.
    //const _firstWeekStartDayIndex = moment(month).startOf('M').weekday();
    //const _lastWeekStartDayIndex = moment(month).endOf('M').weekday();
    const _firstWeekStartDayIndex = moment(month).startOf('M').isoWeekday();
    const _lastWeekStartDayIndex = moment(month).endOf('M').isoWeekday();

    const previousMonthFirstDay = moment(currentMonthFirstDay).subtract(_firstWeekStartDayIndex, 'days').format(dateFormat);
    const nextMonthFirstDay = moment(month).add(1, 'months').format(dateFormat);


    this.previousMonthDays = this.enumerateDaysBetweenDates(previousMonthFirstDay, currentMonthFirstDay);
    const currentMonth = this.enumerateDaysBetweenDates(currentMonthFirstDay, nextMonthFirstDay);

    this.nextMonthDays = [];
    if (_lastWeekStartDayIndex !== 6) {
      const lastDayOfVisibleMonth = moment(nextMonthFirstDay).add(6 - _lastWeekStartDayIndex, 'days').format(dateFormat);
      this.nextMonthDays = this.enumerateDaysBetweenDates(nextMonthFirstDay, lastDayOfVisibleMonth);
    }


    return this.previousMonthDays.concat(currentMonth).concat(this.nextMonthDays);
  }

  // returns array of days from the first day up to, and not including, the last day
  private enumerateDaysBetweenDates = function (startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    currDate = currDate.subtract(1, 'day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(moment(currDate).clone());
    }
    return dates;
  };

  pickedYearToHighlight(year) {
    return this.selectedYear && year === this.selectedYear;
  }

  pickedMonthToHighlight(monthIndex) {
    return this.selectedMonth && (monthIndex + 1) === this.selectedMonth;
  }

  pickedDayToHighlight(day) {
    // if there is a selected day, compare it to each day of the month
    return this.selectedDay && day && moment(day.toDate()).format(dateFormat) === moment(this.selectedDay.toDate()).format(dateFormat);
  }


  unselectedYear(year) {
    return (year > this.dateToday.year() || year < 1850);
  }

  unselectedMonth(month) {
    return (this.selectedYear >= this.dateToday.year() && month > this.dateToday.month());
  }

  unselectedDay(chosenDay) {
    if (this.previousMonthDays.indexOf(chosenDay) > -1 || this.nextMonthDays.indexOf(chosenDay) > -1) {
      return true;
    }

    if (chosenDay.year() >= this.dateToday.year() &&
      chosenDay.month() >= this.dateToday.month() &&
      chosenDay.date() > this.dateToday.date()) {
      return true;
    }

    return false;
  }

  private _toggleBackground() {
    this.renderer.addClass(document.body, 'hidden');
    this.renderer.setStyle(document.querySelector('.modal-overlay'), 'display', 'block');
  }

  toggleCalendar(closeDayCalendar?) {
    if (!this.readonly) {
      if (this.showCalendar) {
        this.OnDestroy();
        this.showCalendar = false;
        this.isPopupActive = false;
        if (this.showMonthsCalendar) {
          this.showYearCalendar = false;
          this.showMonthsCalendar = true;
          this.showDaysCalendar = false;
          return;
        }

        if (this.showDaysCalendar && !closeDayCalendar) {
          this.showYearCalendar = false;
          this.showMonthsCalendar = false;
          this.showDaysCalendar = true;
          return;
        }

        this.showYearCalendar = true;
        this.showMonthsCalendar = false;
        this.showDaysCalendar = false;
        return;
      }
      this._toggleBackground();
      this.showCalendar = true;
      this.isPopupActive = false;
    } else {
      return ;
    }
  }

  get showWeekDayNames() {
    return this.showWeekdays;
  }

  get currentMonth() {
    return this.selectedMonth;
  }

  get monthYear() {
    return this.monthsArr[this.selectedMonth - 1] + ' ' + this.selectedYear;
  }

  get dateToString() {
    // adds leading zeros when appropriate
    if (this.selectedYear || this.selectedMonth || this.selectedDay) {
      return (this.selectedYear ? this.selectedYear : 'YYYY') + '-' +
        (this.selectedMonth ? ('0' + this.selectedMonth).slice(-2) : 'MM') + '-' +
        (this.selectedDay ? ('0' + moment(this.selectedDay.toDate()).format('D')).slice(-2) : this.placeholder.substring(7,9));
    }
    return null;
  }

  onNotify(buttonEventTrigger): void {
    if (buttonEventTrigger === 'toggleCalendar') {
      this.toggleCalendar();
    }
    if (buttonEventTrigger === 'goBack') {
      this.goBack();
    }
  }
}



@Component({
  selector: 'app-date-picker-close-button',
  templateUrl: './app-date-picker-close-button.component.html',
})
export class DatePickerCloseComponent {
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  toggleCalendar() {
    this.notify.emit('toggleCalendar');
  }

}

@Component({
  selector: 'app-date-picker-back-button',
  templateUrl: './app-date-picker-back-button.component.html',
})
export class DatePickerBackComponent {
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  @Input() showDaysCalendar: Boolean = false;

  goBack() {
    this.notify.emit('goBack');
  }
}
