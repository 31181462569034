import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES, productTypeAD, productTypeSD } from '../../config/app-config.constants';
import { Utils } from '../utils/utils.class';

@Injectable({
  providedIn: 'root'
})
export class SessionActiveGuard  {
  constructor( private router: Router){

  }
  canActivate(): boolean {
    const isInSession = !!window.sessionStorage.getItem('InSession');
    console.log('SessionActiveGuard entered can activate InSession', isInSession);
    if (!isInSession) {
      let subProduct = Utils.getSubProduct();
      // console.log('SessionActiveGuard entered if Utils.getSubProduct',Utils.getSubProduct());
      const subProductRegex = new RegExp("[?&]subProduct(=([^&#]*)|&|#|$)"),
        subProductresults = subProductRegex.exec(window.location.href);
      subProduct = subProductresults ? decodeURIComponent(subProductresults[2].replace(/\+/g, " ")) : subProduct;
      // console.log('SessionActiveGuard entered if subProductresults[2], subProduct',subProductresults?.[2],subProduct);

      const queryParams = {
        lang: Utils.getLanguage()
      };
      if (subProduct === productTypeAD) {
        (queryParams as any).subProduct = productTypeAD;
      }

      this.router.navigate(['/'+ APP_ROUTES.set_expectation], {
        queryParams
      });
    }
    console.log('SessionActiveGuard exit can activate isInSession', isInSession);
    return isInSession;
  }
  
}