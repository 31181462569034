import { LoaderService } from './../../shared/service/loader.service';
import { Jquery } from './../../shared/utils/jquery.class';
import $ from 'jquery';
import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Renderer2, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
import { ApplicationService } from '../../shared/service/application.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AD, ANALYTICS, APP_ROUTES, SD, PAGE_TITLES, SPACE, SCREENER_QUESTION_OPTION_CODES, productTypeSD } from './../../config/app-config.constants';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { FormService } from '../../shared/service/form.service';
import { PartyService } from '../../shared/service/party.service';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CdbOlbService } from './../../shared/service/cdb-olb.service';
import { PrefillService } from '../../shared/service/prefill.service';
export class SetExpectationObject {
    isBmoRightForMe: any = '';
    investmentServiceOptionCode: any = '';
    adviceRequiredOptionCode: any = '';
}

@Component({
    selector: 'app-set-expectation',
    templateUrl: './set-expectation.component.html'
})
export class SetExpectationComponent implements OnInit, AfterViewInit {
    public isCash: Boolean = false;
    public isTfsa: Boolean = false;
    public isRRSP: Boolean = false;
    public isSRRSP: Boolean = false;
    public adWorksToggle: Boolean = false;
    public isSubProductAD;
    public isCurrentLangEnglish: Boolean = false;
    public isCurrentLangFrench: Boolean = false;
    public url;
    @ViewChild('setExpectationForm', { static: true }) setExpectationForm: NgForm;
    amlEnabled: boolean;
    cmsMessage: any;
    cmsMessageTitle: any;
    cmsParamExtSubscription: Subscription;
    public resumeApp: Boolean = false;
    public isOlbCdbUser: Boolean = false;
    public isLmeUser: Boolean = false;
    public isNonPilotUser: Boolean = false;
    public systemCriticalError: boolean = false;
    hasDarCookie: boolean = false;
    isBranchAssisted: boolean;
    public investmentServiceOption: string;
    public adviceRequiredOption: string;
    public setExpectationObj: SetExpectationObject = new SetExpectationObject();
    enableOlb = false;
    public showCDBSessionCallError: Boolean = false;
    public isCsaCfrAttorneyEnabled: boolean = false;
    public screenerQ1Options = SCREENER_QUESTION_OPTION_CODES.SQ1;
    public screenerQ2Options = SCREENER_QUESTION_OPTION_CODES.SQ2;
    public disableSetExpectationForm: boolean;
    public isButtonClicked: boolean;
    public subscription: Subscription[] = [];
    public isBill64FeatureEnabled: boolean;
    public isJourneyUser: boolean;
    public isSDOwner: boolean = false;
    public isSubProductSD: boolean;
    public navBackToBil:boolean = true;

    constructor(private router: Router,
        public titleService: Title,
        private route: ActivatedRoute,
        private winRef: WindowRefService,
        private translate: TranslateService,
        private configService: ConfigResolverService<any>,
        private applicationService: ApplicationService,
        private analytics: AnalyticsService,
        public formService: FormService,
        public renderer: Renderer2,
        private partyService: PartyService,
        public el: ElementRef,
        public cdbOlbService: CdbOlbService,
        private prefillService: PrefillService,
        public loaderService: LoaderService) {

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.formService.setActiveComponent('setExpectation', this);
        this.url = '/' + APP_ROUTES.set_expectation;

    }

    ngAfterViewInit() {
        // setTimeout(() => {
        //     this.winRef.nativeWindow.scrollTo(0, 0); // Scrolls to the top.
        //     const ele = this.renderer.selectRootElement('#bilHeader', true);
        //     if (ele) {
        //        ele.focus();
        //     }
        // }, 0)

        this.configureAnalytics();
        // this.analytics.setState('step 3');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('SetExpectation');
        this.analytics.setapiCallDetails({
            apiCallMade: 'false', apiCallType: '', apiCallResponseCode: ''
        });
    }

    ngOnInit() {
        this.isCsaCfrAttorneyEnabled = this.applicationService.getCsaCfrAttorneyFeatureFlag();
        this.hasDarCookie = this.applicationService.cookieStorage.getItem('dar');
        Utils.getIsInSession() != true ? this.applicationService.setResumeLink(true) : this.applicationService.setResumeLink(false);
        Utils.getIsInSession() != true ? this.applicationService.setSignOutLink(false) : this.applicationService.setSignOutLink(true);
        this.amlEnabled = this.configService.getConfig().AML_ENABLED === 'true' ? true : false;
        this.isBranchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
        this.isBill64FeatureEnabled = this.applicationService.getBill64FeatureFlag();
        this.isJourneyUser = Utils.getIsJourneyUser();
        this.isSDOwner = Utils.getIsSDOwner();
        this.isSubProductSD = Utils.getSubProduct() === productTypeSD;

        // MWB-875 : getting user selected account type
        //this.titleService.setTitle(Utils.getPageTitleThingsYouShouldKnow());
        // setting title of the page
        this.route.queryParams.subscribe(params => {

            this.titleService.setTitle(PAGE_TITLES.thingsYouShouldKnowEN);
            // English is set as Default

            if (params.lang === 'fr') {
                this.titleService.setTitle(PAGE_TITLES.thingsYouShouldKnowFR);
            }

            // this.applicationService.getCMSMessage().then(response => {
            //     if (response && Utils.showCMSMessage) {
            //         this.cmsMessage = response['selfServe']['messageBody'];
            //         this.cmsMessageTitle = response['selfServe']['messageTitle'];
            //     }
            // });

        });
        this.cmsParamExtSubscription = this.applicationService.cmsParametersExt$.subscribe(params => {
            if (Utils.showCMSMessage && Utils.getCmsMessageObject()) {
                this.cmsMessage = Utils.getCmsMessageObject()['selfServe']['messageBody'];
                this.cmsMessageTitle = Utils.getCmsMessageObject()['selfServe']['messageTitle'];
            }
        });
        this.subscription.push(this.applicationService.populateSetExpectationForm$.subscribe(res => {
            this.populateFormData();
        }));
        this.subscription.push(this.applicationService.applicationResumed$.subscribe(res => {
            this.resumeApp = Utils.getIsInSession();
        }));
        this.isCash = Utils.isCash();
        this.isTfsa = Utils.isTFSA();
        this.isRRSP = Utils.isRRSP();
        this.isSRRSP = Utils.isSpousalRRSP();
        this.isSubProductAD = Utils.isSubProductAD();

        this.hideDocUploadDocs();
        this.formService.setActiveForm(this.setExpectationForm);
        this.formService.forms.setExpectation.isVisited = true;
        this.resumeApp = Utils.getIsInSession();
        setTimeout(() => {
            this.setGTM();
        }, 200);
        this.populateFormData();
        (Utils.getIsLMEUser() || Utils.getIsJourneyUser()) && this.getLmeUserDetails();
        this.navBackToBil = !(this.formService.forms.setExpectation.isCompleted && this.formService.forms.setExpectation.isVisited);
    }
    getLmeUserDetails() {
        if (Utils.getOlbAppRetrieved() === false && this.applicationService.appReferences) {
            const payload = {
                ecifId: Utils.getOLBUserEcifId() ?? Utils.getJourneyUserEcifId(),
                subProduct: Utils.getSubProduct(),
                cardNo: Utils.getOLBUserCardNumber(),
                userId: Utils.getJourneyUserLoginId()
            };
            this.applicationService.getOlbUserDetails(payload)
                .pipe(
                    takeWhile(() => { return Utils.getAliveOlbService() })
                )
                .subscribe((data: any) => {
                    let response = {};
                    response = data;
                    const cdbOlbUserFirstName = response['parties'][0].personal.identity.legalName.firstName;
                    this.cdbOlbService.cdbOlbUserInfo(cdbOlbUserFirstName);

                    if (response['parties'][0].personal.employment?.employmentStatus && this.applicationService.appReferences.references?.employmentStatuses) { // MWB-17954
                        const userEmpStatus = response['parties'][0].personal.employment.employmentStatus;
                        const employmentStatuses = this.applicationService.appReferences.references.employmentStatuses;
                        const empStatusExist =  employmentStatuses.some(empStatus=>empStatus.code==userEmpStatus);

                        if (!empStatusExist) { // setting users' employmentStatus as empty if it is not existing in reference api employmentStatuses list
                            response['parties'][0].personal.employment.employmentStatus = "";
                        }
                       }

                    Utils.setApplicationObject(response);
                    Utils.setOlbAppRetrieved(true);
                    this.formService.getPayload().setIsApplicantExistingPBClient(response['isApplicantExistingPBClient']);
                    // this.formService.getPayload().setOlbUser(response['olbuser']);
                    this.applicationService.setOLbJourneyStatus(response['ilUser'], response['olbuser'])
                    this.formService.getPayload().setOLAPPayload(response['olapPayload']); // Adding updated XML to Payload object

                    if (response['ilUser']) {
                        // this.formService.getPayload().ilUser = true;
                        if (response['clientAccessId']) {
                            this.formService.getPayload().setClientAccessID(response['clientAccessId']);
                        }
                    }

                    if (response['parties'][0].bmoRelationship) {
                        this.formService.getPayload().setPrivateBankingClient(response['parties'][0].bmoRelationship.isApplicantExistingPBClient);
                    }

                    this.formService.getPayload().setBranchBMORelationship(true); // If olb user  set BMO Relationship to true
                    if (response['accounts'] && response['accounts'][0]) { // no account type - single array
                        this.formService.getPayload().setCardDetails(response['accounts'][0]);
                    }

                    // else {
                    //     this.formService.getPayload().ilUser = false;
                    // }

                    // CONTACT
                    this.prefillService.contactInfoPrefill();
                    this.prefillService.getEmployemntInfo();
                    Utils.setAliveOlbService(false);
                },
                    error => {
                        this.systemCriticalError = true;
                    });
        }

    }
    populateFormData() {
        if (this.formService.forms.setExpectation.data != null) {
            this.disableSetExpectationForm = (this.formService.forms.setExpectation.isVisited && this.formService.forms.setExpectation.data.isBmoRightForMe !== '' && this.formService.forms.setExpectation.data.isBmoRightForMe !== null) ? true : false;
            this.setExpectationObj = this.formService.getFormData('setExpectation');
        } else {
            this.setExpectationObj = new SetExpectationObject()
        }
        this.isOlbCdbUser = Utils.getIsOLBUser();
        this.isLmeUser = Utils.getIsLMEUser();
        this.showCDBSessionCallError = Utils.getShowCDBSessionCallError();
        this.isNonPilotUser = Utils.getIsNonPilotUser();
        if (this.isOlbCdbUser || this.isNonPilotUser) {
            this.applicationService.setResumeLink(false);
        }
    }

    onSubmit() {
        this.isButtonClicked = true;
        Utils.autoFocusFormFirstField(this.setExpectationForm);
        if (this.isCsaCfrAttorneyEnabled && (this.setExpectationObj.isBmoRightForMe === true || this.errForFirstScreenerQuestion() || this.errForSecondScreenerQuestion() || this.displayRequiredErrorMessage())) {
            return false;
        }
        let cmsMessageObject = Utils.getCmsMessageObject();
        this.enableOlb = cmsMessageObject.selfServe.enableOrDisableOlb;
        if (Utils.getIsBranchAssisted() || Utils.getIsOLBUser() || Utils.getIsJourneyUser() || this.formService.forms.setExpectation.isCompleted || !this.enableOlb || this.resumeApp || Utils.getIsNonPilotUser()) {
            this.formService.forms.setExpectation.isCompleted = true;
            this.formService.forms.setExpectation.isVisited = true;
            Utils.setIsSelectAccountClicked(true);
            this.applicationService.saveAndcontinue(this.setExpectationForm, APP_ROUTES.select_account);
        } else {
            Utils.setExpectationFormData(this.setExpectationForm);
            $('.bmo-cdb-login, .modal-overlay').show();
            this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.CDB_OVERLAY_OPENED);
            Jquery.modalFocusTrap('bmo-cdb-login');
        }
    }


    ngOnDestroy() {
        this.cmsParamExtSubscription.unsubscribe();
        this.subscription.forEach(subscription => subscription.unsubscribe());
    }
    showAdWorks() {
        this.applicationService.showHowADworks$.next(true);
    }

    onCloseCMS() {
        Utils.showCMSMessage = false;
        this.cmsMessage = false;
    }

    onResumeApplicationClick() {
        this.applicationService.openResumeApplicationModel();
        this.applicationService.showHowADworks$.next(false);
        this.applicationService.resumeAppID = 'resumeApplicationBottom';
    }

    showDocUploadDocs() {
        $('.modal-overlay').show();
        $('.bmo-doc-upload-setexpect').show();
        Jquery.modalScrollHeight();
        Jquery.modalFocusTrap('bmo-doc-upload-setexpect');
    }
    hideDocUploadDocs() {
        $('.modal-overlay').hide();
        $('.bmo-doc-upload-setexpect').hide();
        Jquery.modalDissableFocusTrap();
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (event.keyCode == SPACE) {
            $("#bmoTitleLink .icon").trigger("click");
            event.preventDefault();
        }
    }


    configureAnalytics() {

        // setting analytics property
        if (Utils.isSubProductAD()) {
            this.analytics.setCustomerSource(AD);
        } else {
            this.analytics.setCustomerSource(SD);
        }

        if (Utils.getIsBranchAssisted() === true) {
            this.analytics.setApplicationFlow(Utils.isBranchSDMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.MULTIASSISTED : ANALYTICS.APPLICATION_FLOW.ASSISTED);
        } else {
            this.analytics.setApplicationFlow(Utils.isMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_MULTI : ANALYTICS.APPLICATION_FLOW.NON_ASSISTED);
        }
    }

    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleThingsYouShouldKnow(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.actionStart,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.account_detail,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': ''
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);
        // console.log(this.winRef.nativeWindow.dataLayer);

    }

    public onBMORightForMeChange(event): void {
        this.formService.setFormData('setExpectation', this.setExpectationObj);
        this.formService.getPayload().setScreenerQuestionsData(this.setExpectationObj);
    }

    public onInvestmentServiceChange(event: any): void {
        this.formService.setFormData('setExpectation', this.setExpectationObj);
        this.formService.getPayload().setScreenerQuestionsData(this.setExpectationObj);
    }

    public onAdviceRequiredChange(event: any): void {
        this.formService.setFormData('setExpectation', this.setExpectationObj);
        this.formService.getPayload().setScreenerQuestionsData(this.setExpectationObj);
    }

    public enableSecondScreenerQuestion() {
        return (this.setExpectationObj.investmentServiceOptionCode == this.screenerQ1Options.A || this.setExpectationObj.investmentServiceOptionCode == this.screenerQ1Options.B) ? true : false;
    }

    public errForFirstScreenerQuestion() {
        return this.setExpectationObj.investmentServiceOptionCode == this.screenerQ1Options.C ? true : false;
    }

    public errForSecondScreenerQuestion() {
        return (this.setExpectationObj.adviceRequiredOptionCode == this.screenerQ2Options.B || this.setExpectationObj.adviceRequiredOptionCode == this.screenerQ2Options.D) ? true : false;
    }
    public displayRequiredErrorMessage() {
        return this.isButtonClicked && this.setExpectationObj.isBmoRightForMe !== true && !this.errForFirstScreenerQuestion() && (this.setExpectationObj.isBmoRightForMe === '' || this.setExpectationObj.investmentServiceOptionCode === '' || this.setExpectationObj.adviceRequiredOptionCode === '') ? true : false;
    }

    public displayDisclaimerPopup() {
        return (this.setExpectationObj.adviceRequiredOptionCode == this.screenerQ2Options.A || this.setExpectationObj.adviceRequiredOptionCode == this.screenerQ2Options.C) && !this.disableSetExpectationForm ? true : false;
    }

    public journeySavedClick(){
        if(!this.navBackToBil){
            $('.bmo-bil-sign-in, .modal-overlay').show();
            this.loaderService.setDisableHideAction(true);
            Jquery.modalFocusTrap('bmo-bil-sign-in');
        } else {
            this.applicationService.navigateToJourneys();
        }
    }

}
