import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Utils } from '../utils/utils.class';

@Injectable()

export class CanActivateGuard  {
  canActivate() {
    return Utils.accountSelected;
  }

}
